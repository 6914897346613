import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    computed: {
        ...mapGetters(["user","usergroup","loggedin"])
    },
    methods: {
        formatDate(datestring) {
            
            // console.log(datestring);
            moment.locale("nl");
            var date = moment(datestring, "DD MMMM YYYY HH:mm");
            // let formattedDate = new Date(datestring).toLocaleDateString('nl-NL', {
            //     year: 'numeric',
            //     month: 'long',
            //     day: '2-digit',
            //     hour: "2-digit",
            //     minute: "2-digit",
            //     hour12: false
            // })

            // console.log(getDate);

            return date.format("DD MMMM YYYY [om] HH:mm");
        },
        formatDateDetailView(datestring) {
            
            // console.log(datestring);
            moment.locale("nl");
            var date = moment(datestring, "YYYY-MM-DDHH:mm");
            // let formattedDate = new Date(datestring).toLocaleDateString('nl-NL', {
            //     year: 'numeric',
            //     month: 'long',
            //     day: '2-digit',
            //     hour: "2-digit",
            //     minute: "2-digit",
            //     hour12: false
            // })

            // console.log(getDate);

            return date.format("DD MMMM YYYY [om] HH:mm");
        },
        formatDateStoring(datestring) {
            
            // console.log(datestring);
            moment.locale("nl");
            var date = moment(datestring, "YYYY-MM-DD HH:mm:ss");
            // let formattedDate = new Date(datestring).toLocaleDateString('nl-NL', {
            //     year: 'numeric',
            //     month: 'long',
            //     day: '2-digit',
            //     hour: "2-digit",
            //     minute: "2-digit",
            //     hour12: false
            // })

            // console.log(getDate);

            return date.format("DD MMMM YYYY [om] HH:mm");
        },
        formatCommentCreatedDate(datestring) {
            let formattedDate = new Date(datestring).toLocaleDateString('nl-NL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })

            return formattedDate;
        },
        formatCommentTime(datestring) {
            let formattedDate = new Date(datestring).toLocaleTimeString('nl-NL', {
                hour: "2-digit",
                minute: "2-digit",
            })

            return formattedDate;
        },
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if(index === 0 || index === allNames.length - 1){
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        },
        logout() {
            this.$store.commit('setLoggedin', false);
            this.$store.commit('resetAll');
            this.$store.commit('setAssumeIdentity', false);

            document.documentElement.style.setProperty('--usergroupColor', '#000');
            // localStorage.removeItem('solunoapp');
            // this.$router.go('/');
            this.$router.push('/');
        },
        getStoringStatus() {
            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;
            return this.$axios.get(cacheBuster(this.apiUrl+'/wp-json/paris/v1/front-end/generic/storingstatus'), {
                params: {
                    userId: this.user.user_id,
                }
            });
        }
        // getUserGroup() {
        //     if(this.loggedin) {

        //         // console.log(this.user.user_id)

        //         this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/usergroup/getusergroup', {
        //             params: {
        //                 id: this.user.user_id
        //             }
        //         }).then(response => {

        //             console.log(response)

        //             this.$axios.get(this.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+response.data.ID).then(response => {
        //                 this.$store.commit('setUsergroup', JSON.stringify(response.data));
        //                 document.documentElement.style.setProperty('--usergroupColor', response.data.acf.kleur);

        //             })
                    
        //         })

        //         // this.$axios.get(this.apiUrl+'/wp-json/wp/v2/users/'+this.user.user_id).then(response => {
        //         //     console.log(response.data)
        //         // })
        //     }
        //     // this.$axios.get(this.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+this.user.user_group).then(response => {
        //     //     this.$store.commit('setUsergroup', JSON.stringify(response.data));
        //     // })
        // }
    },
};