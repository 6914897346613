<template>
    <div class="flex flex-col items-center my-12 md:mt-0 md:mb-6 2k:mt-12 2k:mb-12">
        <span class="text-xs font-bold leading-8" :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'">Live status</span>
        <span :class="maximized ? 'transition-all duration-1000 w-[140px] h-[16px] text-xxs px-2 uppercase text-[rgb(255,255,255)] bg-statusgroen rounded-full' : 'transition-all duration-1000 w-4 h-4 text-xxs uppercase bg-statusgroen text-[rgb(255,255,255)] rounded-full'" v-if="this.storing.count == 0">
            <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'">Systemen up & running</span>
        </span>
        <!-- <span class="text-xxs px-2 uppercase text-[#ffffff] bg-statusrood rounded-full" v-if="this.storing.count > 0">{{ storing.title }}</span> -->
        <router-link :class="[maximized ? 'transition-all duration-1000 w-[140px] h-[16px] text-xxs px-2 uppercase text-[rgb(255,255,255)] rounded-full text-center' : 'transition-all duration-1000 w-4 h-4 text-xxs uppercase text-[rgb(255,255,255)] rounded-full text-center', storingkleur]" v-if="this.storing.count > 0 && this.storing.count == 1" :to="{
            name: 'Bericht',
            params: {
                berichtSlug: storing.slug,
                berichtId: storing.id,
                berichtCat: '',
                berichtCatName: ''
            }
        }">
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem == '' && storing.count == 1">Storing: Algemeen</span>
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem !== '' && storing.count == 1">Storing: {{ storing.acf.storing_systeem }}</span>
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem !== '' && storing.count > 1">Storingen: {{ storing.count }}</span>
        </router-link>
        <router-link :class="[maximized ? 'transition-all duration-1000 w-[140px] h-[16px] text-xxs px-2 uppercase text-[rgb(255,255,255)] rounded-full text-center' : 'transition-all duration-1000 w-4 h-4 text-xxs uppercase text-[rgb(255,255,255)] rounded-full text-center', storingkleur]" v-if="this.storing.count > 1" :to="{
            name: 'Dashboard'
        }">
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem == '' && storing.count == 1">Storing: Algemeen</span>
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem !== '' && storing.count == 1">Storing: {{ storing.acf.storing_systeem }}</span>
        <span :class="maximized ? 'transition-all duration-1000 whitespace-nowrap' : 'transition-all duration-1000 opacity-0 whitespace-nowrap'" v-if="storing.acf.storing_systeem !== '' && storing.count > 1">Storingen: {{ storing.count }}</span>
        </router-link>
        <!-- <span class="text-xxs px-2 uppercase text-[#ffffff] bg-statusoranje rounded-full" v-if="this.storing.count > 0">{{ storing.title }}</span> -->
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "SidebarStatus",
    // data(){
    //     return {
    //         storing: {},
    //         storingActive: false,
    //         storingColor: '',
    //     }
    // },
    props: {
        maximized: Boolean
    },
    computed: {
        ...mapGetters(["storing"]),
        storingkleur() {
            let className = 'default';

            if(this.storing.acf.storingkleur == 'groen') {
                className = 'bg-statusgroen';
            } else if(this.storing.acf.storingkleur == 'oranje') {
                className = 'bg-statusoranje';
            } else if(this.storing.acf.storingkleur == 'rood') {
                className = 'bg-statusrood';
            } else if(this.storing.acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_bg';
            }

            if(this.storing.acf.storing_status == 'afgemeld') {
                className = 'bg-statusgroen';
            } else if(this.storing.acf.storing_status == 'nieuw') {
                className = 'bg-statusrood';
            }

            if(this.storing.count > 1) {
                className = 'bg-statusrood';
            }

            return className;
        }
    }
}
</script>