import { createApp } from 'vue'
import { createStore } from 'vuex'
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import globalMixin from '@/mixins/globalMixin'
import vuejsStorage from 'vuejs-storage'
// import { configureAxios } from './config/axios';

import '@/assets/css/paris.css'

export const store = createStore({
    state () {
      return {
        user: null,
        usergroup: null,
        usergroupImg: null,
        useremail: null,
        verification: null,
        loggedin: false,
        disableSupersearch: false,
        accountId: null,
        mpsAccesstoken: null,
        ticketPageProp: '',
        storing: {},
        currentUg: {},
        assumeIdentity: null,
        artikelCat: null,
        hasJiraPartner: false,
        assumedSubUsergroup: null,
      }
    },
    mutations: {
      resetAll(state) {
        state.user = null,
        state.usergroup = null,
        state.usergroupImg = null,
        state.useremail = null,
        state.verification = null,
        state.disableSupersearch = false,
        state.accountId = null,
        state.mpsAccesstoken = null,
        state.ticketPageProp = '',
        state.storing = {},
        state.currentUg = {},
        state.assumeIdentity = null,
        state.artikelCat = null,
        state.hasJiraPartner = false,
        state.assumedSubUsergroup = null
      },
      setUser (state, input) {
        state.user = input 
      },
      setUsergroup (state, input) {
        state.usergroup = input 
      },
      setUsergroupImg (state, input) {
        state.usergroupImg = input 
      },
      setUserEmail (state, input) {
        state.useremail = input 
      },
      setVerification (state, input) {
        state.verification = input 
      },
      setLoggedin (state, input) {
        state.loggedin = input
      },
      setDisableSupersearch (state, input) {
        state.disableSupersearch = input
      },
      setAccountId (state, input) {
        state.accountId = input 
      },
      setmpsAccesstoken (state, input) {
        state.mpsAccesstoken = input 
      },
      setticketPageProp (state, input) {
        state.ticketPageProp = input 
      },
      setStoring (state, input) {
        state.storing = input 
      },
      setCurrentUg (state, input) {
        state.currentUg = input 
      },
      setAssumeIdentity (state, input) {
        state.assumeIdentity = input 
      },
      setartikelCat (state, input) {
        state.artikelCat = input 
      },
      sethasJiraPartner (state, input) {
        state.hasJiraPartner = input 
      },
      setassumedSubUsergroup (state, input) {
        state.assumedSubUsergroup = input 
      }
    },
    getters: {
        user: state => {
            return JSON.parse(state.user);
        },
        usergroup: state => {
            return JSON.parse(state.usergroup);
        },
        usergroupImg: state => {
            return state.usergroupImg;
        },
        useremail: state => {
            return state.useremail;
        },
        verification: state => {
            return state.verification;
        },
        loggedin: state => {
            return state.loggedin;
        },
        disableSupersearch: state => {
            return state.disableSupersearch;
        },
        accountId: state => {
            return state.accountId;
        },
        mpsAccesstoken: state => {
            return state.mpsAccesstoken;
        },
        ticketPageProp: state => {
            return state.ticketPageProp;
        },
        storing: state => {
            return state.storing;
        },
        currentUg: state => {
            return state.currentUg;
        },
        assumeIdentity: state => {
            return state.assumeIdentity;
        },
        artikelCat: state => {
            return state.artikelCat;
        },
        hasJiraPartner: state => {
            return state.hasJiraPartner;
        },
        assumedSubUsergroup: state => {
            return state.assumedSubUsergroup;
        },
    },
    plugins: [
        vuejsStorage(
            {
                keys: ['user','usergroup','usergroupImg','useremail','verification','loggedin','disableSupersearch','accountId','mpsAccesstoken','ticketPageProp','storing','currentUg','assumeIdentity','artikelCat','hasJiraPartner','assumedSubUsergroup'],
                namespace: 'solunoapp'
            }
        )
    ]
})

// configureAxios();

axios.get('/config/loadconfig').then(response => {
    const apiKey = response.data.headerval;

    // Set the Authorization header with the API key for all future requests
    axios.defaults.headers.common['Authorization'] = apiKey;

    // Now that the custom header is set, initialize the Vue 3 app
    const app = createApp(App);

    app.config.globalProperties.$axios = axios;
    app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL;
    app.config.globalProperties.gtmID = process.env.VUE_APP_GTM_ID;
    app.config.globalProperties.$drawerOpen = true;

    app.config.globalProperties.assumeEmail = null;
    app.config.globalProperties.assumePassword = null;

    app.mixin(globalMixin)
    app.directive('click-outside', {
        bind(el, binding) {
            const handler = (e) => {
                if ((!el.contains(e.target) && el !== e.target)) {
                    binding.value(e);
                }
            }

            el.__vueClickOutside__ = handler;
            document.addEventListener('click', handler);
        },
        unbind(el) {
            document.removeEventListener('click', el.__vueClickOutside__);
            el.__vueClickOutside__ = null;
        }
    });
    app.use(store)
    app.use(router)
    app.use(
        createGtm({
            id: ""+process.env.VUE_APP_GTM_ID+"",
            enabled: true,
            debug: true,
            vueRouter: router,
        })
    )
    app.mount('#app')
  })
  .catch(error => {
    console.error('Failed to fetch custom header:', error);
    
    // Optionally handle the error (e.g., show a loading error page)
  });

// const app = createApp(App);

// axios.defaults.headers.common['Authorization'] = `${process.env.VUE_APP_API_KEY}`;
// axios.defaults.headers.post['Authorization'] = `process.env.VUE_APP_API_KEY`;

