<template>
    <div class="flex flex-row w-full py-2 px-4 bg-[#000] sticky bottom-0 right-0 text-greypunch text-xs" :class="(this.$router.currentRoute.value.name == 'Artikel' || this.$router.currentRoute.value.name == 'Kennisbank' || this.$router.currentRoute.value.name == 'Categorie' || this.$router.currentRoute.value.name == 'Bericht') ? '' : 'hidden'">
        <router-link class="flex flex-row align-middle mr-2 text-greyghost" to="/kennisbank" v-if="this.$router.currentRoute.value.name !== 'Bericht'">
            Kennisbank
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2" preserveAspectRatio="none">
                <path d="M3.00043 8.00185L12.9986 7.99993M12.9986 7.99993L10.3068 5.30715M12.9986 7.99993L10.3068 10.6927" stroke="#999CA0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </router-link>
        <router-link class="flex flex-row align-middle mr-2 text-greyghost" to="/berichten" v-if="this.$router.currentRoute.value.name == 'Bericht'">
            Nieuws
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2" preserveAspectRatio="none">
                <path d="M3.00043 8.00185L12.9986 7.99993M12.9986 7.99993L10.3068 5.30715M12.9986 7.99993L10.3068 10.6927" stroke="#999CA0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </router-link>
        <router-link v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="flex flex-row align-middle mr-2 text-greyghost" :to="{
            name: 'Categorie',
            params: {
                catId: parent,
                subCatId: breadcrumb.id,
                catName: breadcrumb.name,
                catSlug: breadcrumb.name
            }
        }">
            <span v-html="breadcrumb.name"></span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2" preserveAspectRatio="none">
                <path d="M3.00043 8.00185L12.9986 7.99993M12.9986 7.99993L10.3068 5.30715M12.9986 7.99993L10.3068 10.6927" stroke="#999CA0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </router-link>
        <span v-html="curArtikel"></span>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AppBreadcrumbs",
    data() {
        return {
            curCat: '',
            curArtikel: '',
            breadcrumbs: [],
            parent: null,
            noSlugArticle: null
        }
    },
    computed: {
        ...mapGetters(["artikelCat"])
    },
    methods: {
        getBreadcrumbs() {

            // console.log(this.usergroup.id);

            var app = this;

            if(this.$route.params.artikelId !== undefined || this.$route.params.berichtId !== undefined) {

                let postId = null;

                if(this.$route.params.artikelId != undefined) {                
                    postId = this.$route.params.artikelId;
                }

                if(this.$route.params.berichtId != undefined) {                
                    postId = this.$route.params.berichtId;
                }

                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/posts/'+postId).then(response => {
                    this.curArtikel = response.data.title.rendered          
                })
                
            } else {

                const url = window.location.href;
                var index = url.lastIndexOf("/");
                var slug = url.substr(index+1);

                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/posts?slug='+slug+'&_embed').then(response => {

                    if(response.data[0].zichtbaarheid.includes(''+app.usergroup.id+'') == false) {
                        if(response.data[0].zichtbaarheid != app.usergroup.id) {
                            this.$router.push({ name: 'Kennisbank' });
                        }
                        // this.$router.push({ name: 'Kennisbank' });
                        // return;
                    }

                    this.curArtikel = response.data[0].title.rendered;

                    this.noSlugArticle = response.data[0];

                }) 
                
            }

            if(this.$route.params.artikelCat != undefined) {

                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+this.$route.params.artikelCat).then(response => {
                    this.curCat = response.data.name;

                    const level1 = {
                        id: response.data.id,
                        name: response.data.name,
                    }

                    this.breadcrumbs.push(level1);

                    if(response.data.parent != 0) {

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                            if(response.data.name !== 'Mijn Kennisbank') {

                                const level2 = {
                                    id: response.data.id,
                                    name: response.data.name,
                                }

                                this.breadcrumbs.unshift(level2);
                                this.parent = response.data.id;

                                // console.log(this.breadcrumbs);

                            }

                            if(response.data.parent !== 0) {

                                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                                    if(response.data.name !== 'Mijn Kennisbank') {
                                        
                                        const level3 = {
                                            id: response.data.id,
                                            name: response.data.name,
                                        }

                                        this.breadcrumbs.unshift(level3);

                                        // console.log(this.breadcrumbs);

                                    }
                                })

                            }

                        })
                        
                    }
                })

            } else if(this.artikelCat != null) {

                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+this.artikelCat).then(response => {
                    this.curCat = response.data.name;

                    const level1 = {
                        id: response.data.id,
                        name: response.data.name,
                    }

                    this.breadcrumbs.push(level1);

                    if(response.data.parent != 0) {

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                            if(response.data.name !== 'Mijn Kennisbank') {

                                const level2 = {
                                    id: response.data.id,
                                    name: response.data.name,
                                }

                                this.breadcrumbs.unshift(level2);
                                this.parent = response.data.id;

                                // console.log(this.breadcrumbs);

                            }

                            if(response.data.parent !== 0) {

                                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                                    if(response.data.name !== 'Mijn Kennisbank') {
                                        
                                        const level3 = {
                                            id: response.data.id,
                                            name: response.data.name,
                                        }

                                        this.breadcrumbs.unshift(level3);

                                        // console.log(this.breadcrumbs);

                                    }
                                })

                            }

                        })
                        
                    }
                })

                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+this.artikelCat).then(response => {
                    this.curCat = response.data.name;

                    const level1 = {
                        id: response.data.id,
                        name: response.data.name,
                    }

                    this.breadcrumbs.push(level1);

                    if(response.data.parent != 0) {

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                            if(response.data.name !== 'Mijn Kennisbank') {

                                const level2 = {
                                    id: response.data.id,
                                    name: response.data.name,
                                }

                                this.breadcrumbs.unshift(level2);
                                this.parent = response.data.id;

                                // console.log(this.breadcrumbs);

                            }

                            if(response.data.parent !== 0) {

                                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                                    if(response.data.name !== 'Mijn Kennisbank') {
                                        
                                        const level3 = {
                                            id: response.data.id,
                                            name: response.data.name,
                                        }

                                        this.breadcrumbs.unshift(level3);

                                        // console.log(this.breadcrumbs);

                                    }
                                })

                            }

                        })
                        
                    }
                })

            }
        }
    },
    created() {

        if(this.$route.params.artikelId !== undefined || this.$route.params.berichtId !== undefined) {

            let postId = null;

            if(this.$route.params.artikelId != undefined) {                
                postId = this.$route.params.artikelId;
            }

            if(this.$route.params.berichtId != undefined) {                
                postId = this.$route.params.berichtId;
            }

            this.$axios.get(this.apiUrl+'/wp-json/wp/v2/posts/'+postId).then(response => {
                this.curArtikel = response.data.title.rendered          
            })
        }

        if(this.$route.params.artikelCat != undefined) {

            this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+this.$route.params.artikelCat).then(response => {
                this.curCat = response.data.name;

                const level1 = {
                    id: response.data.id,
                    name: response.data.name,
                }

                this.breadcrumbs.push(level1);

                if(response.data.parent != 0) {

                    this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                        if(response.data.name !== 'Mijn Kennisbank') {

                            const level2 = {
                                id: response.data.id,
                                name: response.data.name,
                            }

                            this.breadcrumbs.unshift(level2);
                            this.parent = response.data.id;

                            // console.log(this.breadcrumbs);

                        }

                        if(response.data.parent !== 0) {

                            this.$axios.get(this.apiUrl+'/wp-json/wp/v2/categories/'+response.data.parent).then(response => {

                                if(response.data.name !== 'Mijn Kennisbank') {
                                    
                                    const level3 = {
                                        id: response.data.id,
                                        name: response.data.name,
                                    }

                                    this.breadcrumbs.unshift(level3);

                                    // console.log(this.breadcrumbs);

                                }
                            })

                        }

                    })
                    
                }
            })
            
        } else {
            console.log('leeg');
        }
        
    }
}
</script>