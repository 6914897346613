<template>
    <div v-if="this.storing.count == 1">
        <div class="flex flex-col w-full p-4 mb-6 rounded-lg border-[1px]" :class="storingbgkleur">
            <span class="font-bold text-base leading-6" :class="storingkleur">Momenteel ondervinden we storing</span>
            <span class="text-sm leading-6 mb-6" :class="storingkleur">Storing: {{ storing.title }}</span>
            <router-link class="inline-flex flex-row font-bold text-sm leading-6" :class="storingkleur" :to="{
                name: 'Bericht',
                params: {
                    berichtSlug: storing.slug,
                    berichtId: storing.id,
                    berichtCat: '',
                    berichtCatName: ''
                }
            }">
                Klik hier voor meer informatie
                <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="transition-all ease-in-out group-hover:translate-x-1" preserveAspectRatio="xMidYMid meet"><path class="transition-all ease-in-out" :class="storingkleursvg" d="M5.75 5.25L10.25 12L5.75 18.75" stroke="#000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </router-link>
        </div>
    </div>
    <div class="flex w-full gap-4 lg:flex-row md:flex-col" v-if="this.storing.count > 1">
        <div class="flex flex-col p-4 mb-6 rounded-lg border-[1px] lg:w-1/3 md:w-full" :class="multistoringbgkleur(index)" v-for="(storing, index) in this.storing.storingen" :key="index">
            <span class="font-bold text-base leading-6" :class="multistoringkleur(index)">Momenteel ondervinden we storing</span>
            <span class="text-sm leading-6 mb-6" :class="multistoringkleur(index)">Storing: {{ storing.post_title }}</span>
            <router-link class="inline-flex flex-row font-bold text-sm leading-6" :class="multistoringkleur(index)" :to="{
                name: 'Bericht',
                params: {
                    berichtSlug: storing.post_name,
                    berichtId: storing.ID,
                    berichtCat: '',
                    berichtCatName: ''
                }
            }">
                Klik hier voor meer informatie
                <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="transition-all ease-in-out group-hover:translate-x-1" preserveAspectRatio="xMidYMid meet"><path class="transition-all ease-in-out" :class="storingkleursvg" d="M5.75 5.25L10.25 12L5.75 18.75" stroke="#000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "StatusBanner",
    computed: {
        ...mapGetters(["storing"]),
        storingbgkleur() {
            let className = 'default';

            if(this.storing.acf.storingkleur == 'groen') {
                className = 'bg-statusgroen bg-opacity-20 border-statusgroen';
            } else if(this.storing.acf.storingkleur == 'oranje') {
                className = 'bg-statusoranje bg-opacity-20 border-statusoranje';
            } else if(this.storing.acf.storingkleur == 'rood') {
                className = 'bg-statusrood bg-opacity-20 border-statusrood';
            } else if(this.storing.acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_bg_banner usergroup_bordercolor';
            }

            return className;
        },
        storingkleur() {
            let className = 'default';

            if(this.storing.acf.storingkleur == 'groen') {
                className = 'text-statusgroen ';
            } else if(this.storing.acf.storingkleur == 'oranje') {
                className = 'text-statusoranje';
            } else if(this.storing.acf.storingkleur == 'rood') {
                className = 'text-statusrood';
            } else if(this.storing.acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_txt';
            }

            return className;
        },
        storingkleursvg() {
            let className = 'default';

            if(this.storing.acf.storingkleur == 'groen') {
                className = 'stroke-statusgroen ';
            } else if(this.storing.acf.storingkleur == 'oranje') {
                className = 'stroke-statusoranje';
            } else if(this.storing.acf.storingkleur == 'rood') {
                className = 'stroke-statusrood';
            } else if(this.storing.acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_svg_stroke';
            }

            return className;
        },
    },
    methods: {
        multistoringbgkleur(index) {
            let className = 'default';

            if(this.storing.storingen[index].acf.storingkleur == 'groen') {
                className = 'bg-statusgroen bg-opacity-20 border-statusgroen';
            } else if(this.storing.storingen[index].acf.storingkleur == 'oranje') {
                className = 'bg-statusoranje bg-opacity-20 border-statusoranje';
            } else if(this.storing.storingen[index].acf.storingkleur == 'rood') {
                className = 'bg-statusrood bg-opacity-20 border-statusrood';
            } else if(this.storing.storingen[index].acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_bg_banner usergroup_bordercolor';
            }

            return className;
        },
        multistoringkleur(index) {
            let className = 'default';

            if(this.storing.storingen[index].acf.storingkleur == 'groen') {
                className = 'text-statusgroen ';
            } else if(this.storing.storingen[index].acf.storingkleur == 'oranje') {
                className = 'text-statusoranje';
            } else if(this.storing.storingen[index].acf.storingkleur == 'rood') {
                className = 'text-statusrood';
            } else if(this.storing.storingen[index].acf.storingkleur == 'gebruikersgroep') {
                className = 'usergroup_txt';
            }

            return className;
        },
    }
}
</script>