<template>
    <div class="flex flex-row" :class="this.assumeIdentity ? 'border-l-8 border-dstnyorange' : ''">
        <AppSidebar v-if="this.loggedin" />
        <div id="content" class="w-full h-screen overflow-y-scroll">
            <Transition name="fade" mode="out-in" appear>
                <AppHeader ref="AppHeader" :viewTitle="this.$router.currentRoute.value.name" :key="this.$router.currentRoute.value.name" v-if="this.loggedin" />
            </Transition>
            <div class="px-8 relative" :class="[(this.$router.currentRoute.value.name == 'Artikel' || this.$router.currentRoute.value.name == 'Bericht' ? 'flex flex-col justify-start pb-8 min-h-[calc(100vh-112px)] items-start' : ''), (this.$router.currentRoute.value.name == 'Login' ? 'px-0' : ''), (this.$router.currentRoute.value.name == 'Ticketoverzicht' ? 'h-[calc(100%-126px)]' : ''), (this.$router.currentRoute.value.name == 'Kennisbank' ? 'min-h-full' : ''), (this.$router.currentRoute.value.name !== 'Artikel' && this.$router.currentRoute.value.name !== 'Login' && this.$router.currentRoute.value.name !== 'Ticketoverzicht' && this.$router.currentRoute.value.name !== 'Bericht' ? 'flex w-full justify-center' : '')]">
                <router-view v-slot="{ Component }" ref="view">
                    <Transition name="fade" mode="out-in" appear>
                        <component :is="Component" :key="$route.fullPath" />
                    </Transition>
                </router-view>
            </div>
            <Transition name="fade" mode="out-in" appear>
                <AppBreadcrumbs :key="this.$router.currentRoute.value.name" v-if="this.loggedin" />
            </Transition>
        </div>
        <SuperSearch ref="SuperSearch" />
        <RightSidebar ref="RightSidebar" />
  </div>
</template>

<script>
import AppSidebar from '@/components/Sidebar.vue';
import AppHeader from '@/components/Header.vue';
import AppBreadcrumbs from '@/components/Breadcrumbs.vue';
import SuperSearch from '@/components/Supersearch.vue';
import RightSidebar from '@/components/RightSidebar.vue';
import { mapGetters } from "vuex";

export default {
    name: 'App',
    components: {
        AppSidebar,
        AppHeader,
        AppBreadcrumbs,
        SuperSearch,
        RightSidebar
    },
    data() {
        return {
            usergroupData: null,
            // usergroupColor: '#000'
        }
    },
    computed: {
        ...mapGetters(["loggedin","usergroup","storing","currentUg","assumeIdentity","artikelCat"]),
    },
    created() {
        console.log(process.env.VUE_APP_API_URL);
        if(this.loggedin == true) {
            this.getUserGroup();
            // document.documentElement.style.setProperty('--usergroupColor', this.usergroup.acf.kleur);

            this.getStatus();
            let app = this;

            setInterval(function(){
                app.getStatus();
            }, 60000);
        }

        // this.getUserGroup();
    },
    watch: {
        loggedin(newValue) {
            if(newValue == true) {
                document.documentElement.style.setProperty('--usergroupColor', this.usergroup.acf.kleur);
                document.documentElement.style.setProperty('--usergroupLightColor', this.usergroup.acf.kleur+'80');
                document.documentElement.style.setProperty('--usergroupDisabledColor', this.usergroup.acf.kleur+'32');
                document.documentElement.style.setProperty('--usergroupColorBanner', this.usergroup.acf.kleur+'20');
                document.documentElement.style.setProperty('--usergroupBorderLightColor', this.usergroup.acf.kleur+'20');

                this.getStatus();
            }
        }
    },
    methods: {
        openSuperSearchOutside: function() {
            this.$refs.SuperSearch.setIsOpen()
        },
        getUserGroup() {

            let app = this;

            this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/usergroup/getusergroup?timestamp='+new Date().getTime()+'', {
                params: {
                    id: this.user.user_id
                }
            }).then(response => {

                
                /*
                * -- Check gebruikersgroep tbv Usergroup Admin --
                * Check of de huidig geselecteerde usergroup anders is dan de usergroup van de gebruiker.
                * Als dit zo is dan is een gebruikersgroep admin data van de geselecteerde usergroup aan het bekijken en moet de id van die gebruikersgroep gebruikt worden.
                * Is dit niet zo haal dan gewoon de gebruikersgroep van de gebruiker op.
                */

                // console.log(this.currentUg.ID+' - '+response.data.ID);

                if(this.currentUg.ID != response.data.ID && this.currentUg.ID != undefined) {

                    app.$axios.get(app.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+this.currentUg.ID+'?timestamp='+new Date().getTime()+'').then(response => {
                        var ug = response.data;
                        document.documentElement.style.setProperty('--usergroupColor', response.data.acf.kleur);
                        document.documentElement.style.setProperty('--usergroupLightColor', response.data.acf.kleur+'80');
                        document.documentElement.style.setProperty('--usergroupDisabledColor', response.data.acf.kleur+'32');
                        document.documentElement.style.setProperty('--usergroupColorBanner', this.usergroup.acf.kleur+'20');
                        document.documentElement.style.setProperty('--usergroupBorderLightColor', this.usergroup.acf.kleur+'20');

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/media/'+response.data.acf.klant_logo).then(response => {

                            app.$store.commit('setUsergroup', JSON.stringify(ug));
                            this.$store.commit('setUsergroupImg', response.data.source_url);

                        });
                    })

                } else if(this.currentUg.ID == undefined) {

                    console.log('CURRENT UNDEFINED');

                    app.$axios.get(app.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+response.data.ID+'?timestamp='+new Date().getTime()+'').then(response => {
                        app.$store.commit('setUsergroup', JSON.stringify(response.data));
                        document.documentElement.style.setProperty('--usergroupColor', response.data.acf.kleur);
                        document.documentElement.style.setProperty('--usergroupLightColor', response.data.acf.kleur+'80');
                        document.documentElement.style.setProperty('--usergroupDisabledColor', response.data.acf.kleur+'32');
                        document.documentElement.style.setProperty('--usergroupColorBanner', this.usergroup.acf.kleur+'20');
                        document.documentElement.style.setProperty('--usergroupBorderLightColor', this.usergroup.acf.kleur+'20');

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/media/'+response.data.acf.klant_logo).then(response => {

                            this.$store.commit('setUsergroupImg', response.data.source_url);

                        });
                    })
                    
                } else {

                    app.$axios.get(app.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+response.data.ID+'?timestamp='+new Date().getTime()+'').then(response => {
                        app.$store.commit('setUsergroup', JSON.stringify(response.data));
                        document.documentElement.style.setProperty('--usergroupColor', response.data.acf.kleur);
                        document.documentElement.style.setProperty('--usergroupLightColor', response.data.acf.kleur+'80');
                        document.documentElement.style.setProperty('--usergroupDisabledColor', response.data.acf.kleur+'32');
                        document.documentElement.style.setProperty('--usergroupColorBanner', this.usergroup.acf.kleur+'20');
                        document.documentElement.style.setProperty('--usergroupBorderLightColor', this.usergroup.acf.kleur+'20');

                        this.$axios.get(this.apiUrl+'/wp-json/wp/v2/media/'+response.data.acf.klant_logo).then(response => {

                            this.$store.commit('setUsergroupImg', response.data.source_url);

                        });
                    })

                }
                
            })

            // if(this.loggedin) {

                // console.log(this.user.user_id)

                // this.$axios.get(this.apiUrl+'/wp-json/wp/v2/users/'+this.user.user_id).then(response => {
                //     console.log(response.data)
                // })
            // }
            // this.$axios.get(this.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+this.user.user_group).then(response => {
            //     this.$store.commit('setUsergroup', JSON.stringify(response.data));
            // })
        },
        async getStatus() {
            // var test = {
            //     "id": 15909,
            //     "slug": "one-way-audio-issue", 
            //     "count": 1,
            //     "title": "One way audio issue",
            //     "content": "\n<p>Beste Partner,</p>\n\n\n\n<p><br>Momenteel ervaren wij dat er sporadisch one way audio voorkomt bij gesprekken.<br>Dit kan voor wegvallende gesprekken zorgen.<br>We zijn in onderzoek in samenwerking met verschillende leveranciers.</p>\n\n\n\n<p>Morgen ochtend om 09:00 zullen wij een volgende update plaatsen<br>De waakdienst is uiteraard gewoon bereikbaar.</p>\n",
            //     "acf": {
            //         "storing_systeem": "",
            //         "storingkleur": "rood",
            //         "storing_status": "nieuw",
            //         "update_storing_repeater": 
            //         [
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner,\r\n\r\nWe zijn momenteel nog bezig om dit issue te onderzoeken in samenwerking met onze leveranciers.\r\nOm 11:00 zullen wij een nieuwe update plaatsen.\r\n\r\n&nbsp;\r\n\r\n&nbsp;",
            //                     "is_verstuurd": true
            //                 }
            //             },
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner,\r\n\r\nVoor alsnog is het ons nog niet gelukt om de oorzaak te vinden van dit issue.\r\nWe blijven dit uiteraard onderzoeken in samenwerking met onze leveranciers.\r\nZodra er meer bekend is zullen we dit met jullie delen.\r\n\r\nWe zullen in ieder geval om 13:00 een nieuwe update plaatsen.",
            //                     "is_verstuurd": false
            //                 }
            //             },
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner\r\n\r\nOm 14:30 staat een call geplant met alle belanghebbende partijen.\r\nHierin hopen we tot een oplossing te komen.\r\n\r\nNaar aanleiding van deze call zullen wij een update plaatsen.",
            //                     "is_verstuurd": false
            //                 }
            //             },
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner,\r\n\r\nEr is een tijdelijke aanpassing gedaan om het verkeer te stabiliseren.\r\nDeze work around is gedaan om de root cause beter te kunnen vinden.\r\n\r\nOm half 5 vindt er een nieuwe call plaats tussen alle belanghebbende partijen.\r\n\r\nWe zullen hierna een nieuwe update plaatsen.",
            //                     "is_verstuurd": false
            //                 }
            //             },
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner,\r\n\r\nMomenteel zien wij dat er minder issues zijn met de one way audio en het verkeer lijkt te zijn gestabiliseerd\r\nDe work around is momenteel nog in place en er wordt gekeken naar een permanente oplossing.\r\n\r\nEr lijken nog andere issues te spelen maar voor alsnog lijkt dat niet op ons platform.\r\n\r\nMorgenochtend 09:00 zullen wij een nieuwe update plaatsen.",
            //                     "is_verstuurd": false
            //                 }
            //             },
            //             {
            //                 "update": {
            //                     "bericht": "Beste Partner,\r\n\r\nSinds de aanpassing die wij gisteren hebben gedaan zien wij momenteel een stabiel platform.\r\n\r\nWij komen hier later op terug met een RCA.\r\n\r\nBij deze de officiële afmelding van de verstoring.",
            //                     "is_verstuurd": false
            //                 }
            //             }
            //         ]
            //     }
            // };
            // this.$store.commit('setStoring', test);

            let response = await this.getStoringStatus();
            this.$store.commit('setStoring', response.data);
        }
    }
}
</script>
<style>
@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-enter-active {
    animation: fade 0.5s;
}

.fade-leave-active {
    animation: fade 0.5s reverse;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    border-color: var(--usergroupColor) !important;
    --tw-ring-color: var(--usergroupColor) !important;
}

.vs__search, .vs__search:focus {
    border: 0 none;
}

.activetab {
    position:relative;
    color: var(--usergroupColor) !important;
    border-color: var(--usergroupColor) !important;
}

.activetab svg path {
    stroke: var(--usergroupColor) !important;
}

.activetab::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid var(--usergroupColor) !important;
    width: 100%;
    bottom: -1px;
    left: 0px;
}

.activetab-verticaal {
    position:relative;
    color: var(--usergroupColor) !important;
    border-color: var(--usergroupColor) !important;
}

.activetab-verticaal svg path {
    stroke: var(--usergroupColor) !important;
}

.activetab-verticaal-fill svg path {
    fill: var(--usergroupColor) !important;
}

.activetab-verticaal::after {
    position: absolute;
    content: "";
    border-right: 1px solid var(--usergroupColor) !important;
    height: 100%;
    right: -1px;
    top: 0px;
}

.usergroup_bg {
    background-color: var(--usergroupColor) !important;
}

.peer:checked ~ .peer-checked\:usergroup_bg {
    background-color: var(--usergroupColor) !important;
}

.usergroup_bg_banner {
    background-color: var(--usergroupColorBanner) !important;
}

.usergroup_bg p font,
.usergroup_bg table tr td font {
    color: #fff !important;
}

.usergroup_txt {
    color: var(--usergroupColor) !important;
}

.usergroup_bordercolor {
    border-color: var(--usergroupColor) !important;
}

.usergroup_svg_stroke {
    stroke: var(--usergroupColor) !important;
}

.usergroup_txt svg path {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-delay: 200ms;
    transition-duration: 1000ms;
    fill: var(--usergroupColor) !important;
}

.usergroup_svgfill {
    fill: var(--usergroupColor) !important;
}

#quicklinks a:hover {
    box-shadow: inset 0 0 0 1px var(--usergroupColor);
}

#keuze_ticketsoort > div:hover {
    border-color: var(--usergroupColor) !important;
}

.verderlezen:hover span,
.instellingtab:hover {
    color: var(--usergroupColor) !important;
}

.verderlezen:hover svg path,
.instellingtab:hover svg path {
    stroke: var(--usergroupColor) !important;
}

.filepond--drop-label {
    display: block !important;
}

.filepond--drop-label label {
    line-height: 60px;
}
.filemsg {
    margin-top:10px;
}
.bijlage:hover {
    cursor:pointer;
    color:var(--usergroupColor);
}
</style>