<template>

    <!-- <PerfectScrollbar class="flex flex-col w-full pb-8 pr-8 overflow-y-scroll" :options="scrollbaroptions" v-if="ticket"> -->
    <div class="flex flex-col w-full h-[calc(100%-52px)]" v-if="ticket">

        <!-- <label class="font-bold">Klant</label>
        {{ticket.renderedFields.customfield_10136}}

        <label class="font-bold mt-8">Label</label>
        {{ticket.fields.components[0].name}}

        <label class="font-bold mt-8">Onderwerp</label>
        {{ticket.fields.summary}}

        <label class="font-bold mt-8">Bijlages</label>
        <a href="javascript:;" v-on:click="getAttachment(file.id,file.filename)" v-for="file in ticket.fields.attachment" :key="file.id">{{file.filename}}</a>

        <label class="font-bold mt-8">Inhoud en/of communicatie</label>
        <div v-if="ticket.fields.customfield_10010.requestType.name == 'Ik wil een incident melden'">
            <strong>Onderwerp</strong><br/>
            {{ticket.fields.summary}}<br/><br/>

            <strong>Wat probeert de klant te bereiken?</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10419" style="padding:0px"></div><br/>

            <strong>Beschrijf welke stappen je onderneemt en waar het mist gaat.</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10420" style="padding:0px"></div><br/>

            <strong>Welke zaken heb je ondernomen om het probleem zelf op te lossen?</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10421" style="padding:0px"></div><br/>

            <strong>Heeft een wijziging plaats gevonden of zijn er andere zaken die je opvallen?</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10421" style="padding:0px"></div><br/>

            <strong>Indien van toepassing graag een voorbeeld waarbij het mis gaat</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10423" style="padding:0px"></div><br/>
        </div>
        <div v-if="ticket.fields.customfield_10010.requestType.name == 'Ik heb een vraag/ service request'">
            <strong>Onderwerp</strong><br/>
            {{ticket.fields.summary}}<br/><br/>

            <strong>Stel hier je vraag</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10424" style="padding:0px"></div><br/>

            <strong>Waarvoor heb je het nodig?</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10425" style="padding:0px"></div><br/>

            <strong>Wie kunnen we benaderen voor additionele informatie?</strong><br/>
            <div v-html="ticket.renderedFields.customfield_10426" style="padding:0px"></div><br/>
        </div>
        <div v-if="ticket.renderedFields.description !== null && ticket.renderedFields.customfield_10419 == '' && ticket.renderedFields.customfield_10424 == ''" v-html="ticket.renderedFields.description">
        </div>
        <div v-if="ticket.renderedFields.description == null">
        </div> -->

        <TabGroup @change="changeTab">
            <TabList class="inline-flex w-full pt-2 border-b-[1px] border-greypunch sticky top-0 bg-[#fff] z-50">
                <Tab v-slot="{ selected }" as="template">
                    <span class="p-2 font-semibold text-sm rounded-t hover:cursor-pointer focus:outline-none" :class="[selected ? 'rounded-t activetab' : '']">Ticket</span>
                </Tab>
                <Tab v-slot="{ selected }" as="template">
                    <span class="p-2 font-semibold text-sm rounded-t hover:cursor-pointer focus:outline-none" :class="[selected ? 'rounded-t activetab' : '']">Communicatie</span>
                </Tab>
            </TabList>
            <TabPanels class="h-full">
                <TabPanel class="h-full">
                    <PerfectScrollbar class="h-full pr-4 pt-6">
                        <div v-if="ticket.fields.customfield_10010 !== null && ticket.fields.customfield_10010.requestType.name == 'Ik wil een incident melden'">
                            <div class="flex flex-row justify-between p-3 border-[1px] bg-[#F7F7F7] border-[#D1D5D9] rounded-lg mb-6">
                                <span>Update: {{ this.formatDate(ticket.fields.updated) }}</span>
                                <span>Aangemaakt: {{ this.formatDate(ticket.fields.created) }}</span>
                            </div>
                            <div class="flex flex-col justify-start mb-5">
                                <label class="mb-2">Onderwerp</label>
                                <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.fields.summary" disabled />
                            </div>
                            <div class="flex flex-col justify-start mb-5">
                                <!-- <label class="flex flex-row justify-between">Klant <span class="self-center text-xs hover:cursor-pointer" v-on:click="showKlantEdit = !showKlantEdit">Edit klant</span></label> -->
                                <label class="flex flex-row justify-between">Klant</label>
                                
                                <v-select v-model="ticket.renderedFields.customfield_10136" :options="tenantOptions" label="name" placeholder="Kies een klant" @option:selected="saveTenant" :disabled="this.usergroup.acf.ticket_systeem == '1' ? 'true' : 'false'">
                                    <template #search="{ attributes, events }">
                                        <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <input class="vs__search" v-bind="attributes" v-on="events">                
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes">
                                            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </template>
                                </v-select>
                                <!-- <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.renderedFields.customfield_10136" v-if="!showKlantEdit" disabled /> -->
                                <!-- <v-select v-model="editKlant" :options="tenantOptions" label="name" v-if="showKlantEdit"></v-select> -->
                                <button class="self-end w-36 mt-2 px-2 py-2 usergroup_bg text-[#ffffff] text-xs rounded-full" v-if="showKlantEdit" v-on:click="saveTenant()">Klant opslaan</button>
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.fields.components.length > 0">
                                <label class="mb-2">Label</label>
                                <!-- <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.fields.components[0].name" disabled /> -->

                                <v-select v-model="ticket.fields.components[0].name" placeholder="Kies een label" disabled="true">
                                    <template #search="{ attributes, events }">
                                        <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.35577 12.9615C6.35577 12.3402 6.85945 11.8365 7.48077 11.8365H8.51923C9.14055 11.8365 9.64423 12.3402 9.64423 12.9615V14C9.64423 14.6213 9.14055 15.125 8.51923 15.125H7.48077C6.85945 15.125 6.35577 14.6213 6.35577 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M6.35577 7.48077C6.35577 6.85945 6.85945 6.35577 7.48077 6.35577H8.51923C9.14055 6.35577 9.64423 6.85945 9.64423 7.48077V8.51923C9.64423 9.14055 9.14055 9.64423 8.51923 9.64423H7.48077C6.85945 9.64423 6.35577 9.14055 6.35577 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M0.875 12.9615C0.875 12.3402 1.37868 11.8365 2 11.8365H3.03846C3.65978 11.8365 4.16346 12.3402 4.16346 12.9615V14C4.16346 14.6213 3.65978 15.125 3.03846 15.125H2C1.37868 15.125 0.875 14.6213 0.875 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M11.8365 12.9615C11.8365 12.3402 12.3402 11.8365 12.9615 11.8365H14C14.6213 11.8365 15.125 12.3402 15.125 12.9615V14C15.125 14.6213 14.6213 15.125 14 15.125H12.9615C12.3402 15.125 11.8365 14.6213 11.8365 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M11.8365 7.48077C11.8365 6.85945 12.3402 6.35577 12.9615 6.35577H14C14.6213 6.35577 15.125 6.85945 15.125 7.48077V8.51923C15.125 9.14055 14.6213 9.64423 14 9.64423H12.9615C12.3402 9.64423 11.8365 9.14055 11.8365 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M6.35577 2C6.35577 1.37868 6.85945 0.875 7.48077 0.875H8.51923C9.14055 0.875 9.64423 1.37868 9.64423 2V3.03846C9.64423 3.65978 9.14055 4.16346 8.51923 4.16346H7.48077C6.85945 4.16346 6.35577 3.65978 6.35577 3.03846V2Z" stroke="black" stroke-linecap="round"/>
                                        </svg>
                                        <input class="vs__search" v-bind="attributes" v-on="events">                
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes">
                                            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                            <!-- <div class="flex flex-col justify-start mb-5">
                                <label class="mb-2">Bijlages</label>
                                <a href="javascript:;" v-on:click="getAttachment(file.id,file.filename)" v-for="file in ticket.fields.attachment" :key="file.id" class="bijlage">{{file.filename}}</a>
                            </div> -->
                            <div class="flex flex-row flex-wrap justify-start mb-5 gap-2" v-if="ticket.fields.attachment.length > 0">
                                <label class="w-full mb-3">Bijlages</label>
                                <div class="bijlage flex flex-col items-center justify-center w-32 h-32 p-4 border-[1px] border-greypunch rounded-lg hover:bg-galwaygrey" v-for="file in ticket.fields.attachment" :key="file.id">
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/jpg.svg" v-if="file.filename.split('.').pop() == 'jpg' || file.filename.split('.').pop() == 'jpeg'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/png.svg" v-else-if="file.filename.split('.').pop() == 'png'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/gif.svg" v-else-if="file.filename.split('.').pop() == 'gif'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/svg.svg" v-else-if="file.filename.split('.').pop() == 'svg'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xls.svg" v-else-if="file.filename.split('.').pop() == 'xls' || file.filename.split('.').pop() == 'xlsx'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/ppt.svg" v-else-if="file.filename.split('.').pop() == 'ppt' || file.filename.split('.').pop() == 'pptx'" />                                
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/doc.svg" v-else-if="file.filename.split('.').pop() == 'doc' || file.filename.split('.').pop() == 'doc'" />   
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xml.svg" v-else-if="file.filename.split('.').pop() == 'xml'" />                                
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/json.svg" v-else-if="file.filename.split('.').pop() == 'json'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp4.svg" v-else-if="file.filename.split('.').pop() == 'mp4'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp3.svg" v-else-if="file.filename.split('.').pop() == 'mp3'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/wmv.svg" v-else-if="file.filename.split('.').pop() == 'wmv'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mail.svg" v-else-if="file.filename.split('.').pop() == 'msg'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/pdf.svg" v-else-if="file.filename.split('.').pop() == 'pdf'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/csv.svg" v-else-if="file.filename.split('.').pop() == 'csv'" />
                                    <a class="text-xs mt-3" href="javascript:;" v-on:click="getAttachment(file.id,file.filename)">{{file.filename.substring(0,10)}}.{{ file.filename.split('.').pop() }}</a>
                                </div>
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10419">
                                <label class="mb-2">Wat probeert de klant te bereiken?</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10419" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10420">
                                <label class="mb-2">Beschrijf welke stappen je onderneemt en waar het mis gaat.</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10420" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10421">
                                <label class="mb-2">Welke zaken heb je ondernomen om het probleem zelf op te lossen?</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10421" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10422">
                                <label class="mb-2">Heeft een wijziging plaats gevonden of zijn er andere zaken die je opvallen?</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10422" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10423">
                                <label class="mb-2">Indien van toepassing graag een voorbeeld waarbij het mis gaat</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10423" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <!-- 
                            Staat uit, want eigenlijk alleen visible in jira zelf.
                            <div class="flex flex-col justify-start mb-5">
                                <label>Omschrijving</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.description" theme="snow" toolbar="minimal" :options="options" />
                            </div> -->
                        </div>

                        <div v-if="ticket.fields.customfield_10010 !== null && ticket.fields.customfield_10010.requestType.name == 'Ik heb een vraag/ service request'">
                            <div class="flex flex-row justify-between p-3 border-[1px] bg-[#F7F7F7] border-[#D1D5D9] rounded-lg mb-6 text-greyghost">
                                <span class="flex flex-row gap-2">
                                    <img src="@/assets/img/Calendar.svg" class="m-0" />
                                    Update: {{ this.formatDate(ticket.fields.updated) }}
                                </span>
                                <span class="flex flex-row gap-2">
                                    <img src="@/assets/img/Calendar.svg" class="m-0" />
                                    Aangemaakt: {{ this.formatDate(ticket.fields.created) }}
                                </span>
                            </div>
                            <div class="flex flex-col justify-start mb-5">
                                <label>Onderwerp</label>
                                <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.fields.summary" disabled />
                            </div>
                            <div class="flex flex-col justify-start mb-5">
                                <!-- <label class="flex flex-row justify-between">Klant <span class="self-center text-xs hover:cursor-pointer" v-on:click="showKlantEdit = !showKlantEdit">Edit klant</span></label> -->
                                <label class="flex flex-row justify-between">Klant</label>
                                <v-select v-model="ticket.renderedFields.customfield_10136" :options="tenantOptions" label="name" placeholder="Kies een klant" @option:selected="saveTenant" :disabled="this.usergroup.acf.ticket_systeem == '1' ? 'true' : 'false'">
                                    <template #search="{ attributes, events }">
                                        <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <input class="vs__search" v-bind="attributes" v-on="events">                
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes">
                                            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </template>
                                </v-select>
                                <!-- <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.renderedFields.customfield_10136" v-if="!showKlantEdit" disabled /> -->
                                <!-- <v-select v-model="editKlant" :options="tenantOptions" label="name" v-if="showKlantEdit"></v-select> -->
                                <!-- <button class="self-end w-36 mt-2 px-2 py-2 usergroup_bg text-[#ffffff] text-xs rounded-full" v-if="showKlantEdit" v-on:click="saveTenant()">Klant opslaan</button> -->
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.fields.components.length > 0">
                                <label>Label</label>
                                <!-- <input type="text" class="border-[1px] border-greypunch rounded-md mb-5 disabled:bg-backgroundgrey disabled:text-greyghost" :value="ticket.fields.components[0].name" v-if="ticket.fields.components.length > 0" disabled /> -->
                                <v-select v-model="ticket.fields.components[0].name" placeholder="Kies een label" disabled="true">
                                    <template #search="{ attributes, events }">
                                        <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.35577 12.9615C6.35577 12.3402 6.85945 11.8365 7.48077 11.8365H8.51923C9.14055 11.8365 9.64423 12.3402 9.64423 12.9615V14C9.64423 14.6213 9.14055 15.125 8.51923 15.125H7.48077C6.85945 15.125 6.35577 14.6213 6.35577 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M6.35577 7.48077C6.35577 6.85945 6.85945 6.35577 7.48077 6.35577H8.51923C9.14055 6.35577 9.64423 6.85945 9.64423 7.48077V8.51923C9.64423 9.14055 9.14055 9.64423 8.51923 9.64423H7.48077C6.85945 9.64423 6.35577 9.14055 6.35577 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M0.875 12.9615C0.875 12.3402 1.37868 11.8365 2 11.8365H3.03846C3.65978 11.8365 4.16346 12.3402 4.16346 12.9615V14C4.16346 14.6213 3.65978 15.125 3.03846 15.125H2C1.37868 15.125 0.875 14.6213 0.875 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M11.8365 12.9615C11.8365 12.3402 12.3402 11.8365 12.9615 11.8365H14C14.6213 11.8365 15.125 12.3402 15.125 12.9615V14C15.125 14.6213 14.6213 15.125 14 15.125H12.9615C12.3402 15.125 11.8365 14.6213 11.8365 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M11.8365 7.48077C11.8365 6.85945 12.3402 6.35577 12.9615 6.35577H14C14.6213 6.35577 15.125 6.85945 15.125 7.48077V8.51923C15.125 9.14055 14.6213 9.64423 14 9.64423H12.9615C12.3402 9.64423 11.8365 9.14055 11.8365 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                            <path d="M6.35577 2C6.35577 1.37868 6.85945 0.875 7.48077 0.875H8.51923C9.14055 0.875 9.64423 1.37868 9.64423 2V3.03846C9.64423 3.65978 9.14055 4.16346 8.51923 4.16346H7.48077C6.85945 4.16346 6.35577 3.65978 6.35577 3.03846V2Z" stroke="black" stroke-linecap="round"/>
                                        </svg>
                                        <input class="vs__search" v-bind="attributes" v-on="events">                
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes">
                                            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                            <div class="flex flex-row flex-wrap justify-start mb-5 gap-2" v-if="ticket.fields.attachment.length > 0">
                                <label class="w-full mb-3">Bijlages</label>
                                <div class="bijlage flex flex-col items-center justify-center w-32 h-32 p-4 border-[1px] border-greypunch rounded-lg hover:bg-galwaygrey" v-for="file in ticket.fields.attachment" :key="file.id">
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/jpg.svg" v-if="file.filename.split('.').pop() == 'jpg' || file.filename.split('.').pop() == 'jpeg'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/png.svg" v-else-if="file.filename.split('.').pop() == 'png'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/gif.svg" v-else-if="file.filename.split('.').pop() == 'gif'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/svg.svg" v-else-if="file.filename.split('.').pop() == 'svg'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xls.svg" v-else-if="file.filename.split('.').pop() == 'xls' || file.filename.split('.').pop() == 'xlsx'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/ppt.svg" v-else-if="file.filename.split('.').pop() == 'ppt' || file.filename.split('.').pop() == 'pptx'" />                                
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/doc.svg" v-else-if="file.filename.split('.').pop() == 'doc' || file.filename.split('.').pop() == 'doc'" />   
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xml.svg" v-else-if="file.filename.split('.').pop() == 'xml'" />                                
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/json.svg" v-else-if="file.filename.split('.').pop() == 'json'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp4.svg" v-else-if="file.filename.split('.').pop() == 'mp4'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp3.svg" v-else-if="file.filename.split('.').pop() == 'mp3'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/wmv.svg" v-else-if="file.filename.split('.').pop() == 'wmv'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mail.svg" v-else-if="file.filename.split('.').pop() == 'msg'" /> 
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/pdf.svg" v-else-if="file.filename.split('.').pop() == 'pdf'" />
                                    <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/csv.svg" v-else-if="file.filename.split('.').pop() == 'csv'" />
                                    <a class="text-xs mt-3" href="javascript:;" v-on:click="getAttachment(file.id,file.filename)">{{file.filename.substring(0,10)}}.{{ file.filename.split('.').pop() }}</a>
                                </div>
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10424">
                                <label>Stel hier je vraag</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10424" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10425">
                                <label>Waarvoor heb je het nodig? Dit helpt ons om mee te denken mocht het niet mogelijk zijn</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10425" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.customfield_10426">
                                <label>Wie kunnen we benaderen voor additionele informatie?</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.customfield_10426" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <!-- 
                            Staat uit, want eigenlijk alleen visible in jira zelf.    
                            <div class="flex flex-col justify-start mb-5">
                                <label>Omschrijving</label>
                                <QuillEditor content-type="html" v-model:content="ticket.renderedFields.description" theme="snow" toolbar="minimal" :options="options" />
                            </div> -->
                        </div>

                        <div v-if="ticket.fields.customfield_10010 !== null && (ticket.fields.customfield_10010.requestType.issueTypeId == '10172' || ticket.fields.customfield_10010.requestType.issueTypeId == '10019' || ticket.fields.customfield_10010.requestType.issueTypeId == '10021' || ticket.fields.customfield_10010.requestType.issueTypeId == '10020'  || ticket.fields.customfield_10010.requestType.issueTypeId == '10004')">
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.description">
                                <!-- <QuillEditor ref="mailContent" content-type="html" v-model:content="ticket.renderedFields.description" theme="snow" toolbar="minimal" :options="options" /> -->
                                <div v-html="ticket.renderedFields.description" class="p-3 border-[1px] bg-[#F7F7F7] border-[#D1D5D9] rounded-lg"></div>
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-else>
                                <QuillEditor ref="mailContent" content-type="html" v-model:content="ticket.fields.summary" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                        </div>

                        <div v-if="ticket.fields.customfield_10010 == null">
                            <div class="flex flex-col justify-start mb-5" v-if="ticket.renderedFields.description">
                                <QuillEditor ref="mailContent" content-type="html" v-model:content="ticket.renderedFields.description" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                            <div class="flex flex-col justify-start mb-5" v-else>
                                <QuillEditor ref="mailContent" content-type="html" v-model:content="ticket.fields.summary" theme="snow" toolbar="minimal" :options="options" />
                            </div>
                        </div>
                    </PerfectScrollbar>
                </TabPanel>
                <TabPanel class="h-full overflow-y-scroll">                   
                    <!-- <PerfectScrollbar class="flex flex-col h-full" :options="scrollbaroptions" ref="commentscroll"> -->
                    <div class="grid grid-cols-12 gap-y-2">
                        <div class="col-start-1 col-end-12 p-3 mb-16 rounded-lg comment" v-for="comment in ticketComments" :key="comment.id">
                            <div class="flex items-center mb-2" :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'flex-row' : 'flex-row-reverse']">
                                <div class="flex items-center justify-center h-10 w-10 rounded-full usergroup_bg text-[#fff] flex-shrink-0" :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'mr-2' : 'ml-2']" v-html="getInitials(comment.updateAuthor.displayName)"></div>
                                <span :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'mr-2' : 'ml-2']">{{comment.updateAuthor.displayName}}</span>
                                <span class="text-greyghost">{{formatCommentCreatedDate(comment.created)}}</span>
                            </div>
                            <div class="relative flex items-center" :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'flex-row' : 'flex-row-reverse']">
                                <div class="relative max-w-full ml-12 text-sm py-2 px-4 shadow rounded-xl" :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'usergroup_bg text-[#fff] rounded-tl-none' : 'bg-backgroundgrey rounded-tr-none']" v-html="transformComment(comment.renderedBody)" @click="handleBijlageClick"></div>
                                <div class="absolute -bottom-8 text-sm" :class="[(comment.updateAuthor.accountType == 'customer' || comment.updateAuthor.accountId == accountId) ? 'left-12' : 'right-0']">{{formatCommentTime(comment.created)}}</div>
                            </div>
                            
                        </div>
                    </div>
                    <!-- </PerfectScrollbar> -->
                    <div class="flex flex-col absolute p-8 -bottom-44 -left-8 w-[calc(100%+4rem)] min-h-44 bg-[#fff] border-t-[1px] border-backgroundgrey">
                        <textarea rows="1" placeholder="Type iets" v-model="newCommentTekst" class="usergroup-input w-full min-h-12 h-fit rounded-3xl border-galwaygrey text-[#000] mb-7 resize-none overflow-hidden focus:pl-8 transition-all" @keyup.ctrl.enter.exact="addComment()" @keyup.enter.shift.exact="newCommentTekst += ''" ref="newComment"></textarea>
                        <div class="flex flex-row items-center justify-end">
                            <span class="relative flex w-8 h-8 mr-8 justify-center items-center rounded-full bg-backgroundgrey hover:cursor-pointer" @click="showFileUpload()">
                                <span class="absolute w-4 h-4 -top-2 -right-2 usergroup_bg rounded-full text-[#fff] text-xs text-center transition-all duration-700" :class="uploadFiles.length ? '' : 'hidden'">{{uploadFiles.length}}</span>
                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.9621 13.6086L10.1673 9.40341C10.8504 8.72034 10.8765 7.63896 10.2256 6.98808C9.57471 6.33721 8.52417 6.39415 7.84109 7.07723L1.8311 13.0255C0.464948 14.3917 0.41274 16.5544 1.71449 17.8562C3.01624 19.1579 5.17899 19.1057 6.54514 17.7396L14.5032 9.78151C16.5524 7.73229 16.6307 4.48816 14.6781 2.53553C12.7255 0.582912 9.48135 0.661223 7.43213 2.71045L1.51617 8.62641" stroke="black" stroke-linecap="round"/>
                                </svg>
                            </span>
                            <span class="usergroup-button flex flex-row px-5 py-2 items-center place-self-end text-[#fff] hover:cursor-pointer usergroup_bg rounded-full" @click="addComment()">
                                Verzenden
                                <svg width="32" height="32" class="ml-4"  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5082 23.8897L16.6434 24.3918L17.5082 23.8897ZM13.7119 18.0794L13.2951 18.9884L13.7119 18.0794ZM14.608 18.8934L13.7431 19.3954L14.608 18.8934ZM22.3984 7.24843L22.0709 6.30358L22.3984 7.24843ZM24.9806 9.67299L24.017 9.40556L24.9806 9.67299ZM22.0709 6.30358L8.37885 11.0497L9.03388 12.9394L22.7259 8.19328L22.0709 6.30358ZM8.11093 16.6112L13.2951 18.9884L14.1287 17.1705L8.94458 14.7932L8.11093 16.6112ZM13.7431 19.3954L16.6434 24.3918L18.3731 23.3877L15.4728 18.3914L13.7431 19.3954ZM22.1286 23.688L25.9442 9.94042L24.017 9.40556L20.2015 23.1531L22.1286 23.688ZM16.6434 24.3918C17.9769 26.6892 21.4182 26.2477 22.1286 23.688L20.2015 23.1531C19.9647 24.0064 18.8176 24.1535 18.3731 23.3877L16.6434 24.3918ZM13.2951 18.9884C13.4829 19.0745 13.6394 19.2167 13.7431 19.3954L15.4728 18.3914C15.1617 17.8553 14.6921 17.4288 14.1287 17.1705L13.2951 18.9884ZM8.37885 11.0497C5.82367 11.9354 5.65274 15.484 8.11093 16.6112L8.94458 14.7932C8.12517 14.4175 8.18216 13.2346 9.03388 12.9394L8.37885 11.0497ZM22.7259 8.19328C23.4876 7.92925 24.2326 8.62878 24.017 9.40556L25.9442 9.94042C26.5909 7.61009 24.3559 5.51151 22.0709 6.30358L22.7259 8.19328Z" fill="#ffffff"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </TabPanel>
            </TabPanels>
        </TabGroup>

        <!-- acceptedFileTypes="image/jpeg, image/png, text/xml, text/csv, text/plain, application/xml, application/zip, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-outlook" -->
        <!-- acceptedFileTypes="image/jpeg,image/png,text/xml,text/csv,text/plain,application/xml,application/zip,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-outlook" -->

        <div id="upload" class="flex absolute -top-8 -left-8 w-[calc(100%+4rem)] h-[calc(100%+2rem)] bg-[#000] bg-opacity-80 z-50 justify-center items-center transition-all duration-1000" :class="fileUploadVisible ? '' : 'hidden'" @click="showFileUpload()">
            <file-pond
                :name="'input_'+findex"
                class="w-6/12"
                ref="pondfiles"
                label-idle="Drop files here..."
                v-bind:allow-multiple="true"
                :files="uploadFiles"
                v-on:init="handleFilePondInit"
                v-on:updatefiles="pushToUploads"
            />
        </div>

    </div>
    <!-- </PerfectScrollbar> -->

</template>
<script>
import vSelect from 'vue-select'

import { QuillEditor } from '@vueup/vue-quill'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import vueFilePond, { setOptions } from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

setOptions({
    credits: false,
});
const FilePond = vueFilePond(FilePondPluginImagePreview,FilePondPluginFileValidateType);

import { mapGetters } from "vuex";

export default {
    name: 'TicketDetails',
    components: {
        vSelect,
        QuillEditor,
        TabGroup,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
        FilePond,
        PerfectScrollbar
    },
    data() {
        return {
            ticket: null,
            options: {
                debug: 'info',
                modules: {
                    // toolbar: ['bold', 'italic', 'underline']
                    toolbar: ""
                },
                // placeholder: 'Compose an epic...',
                content: 'TEST',
                readOnly: true,
                theme: 'snow'
            },
            ticketComments: [],
            newCommentTekst: '',
            fileUploadVisible: false,
            uploadFiles: [],
            showKlantEdit: false,
            tenantOptions: [],
            editKlant: [],
            scrollbaroptions: {
                suppressScrollX: true
            },
        }
    },
    props: {
        ticketId: String,
        ticketKey: String
    },
    computed: {
        ...mapGetters(["accountId","mpsAccesstoken","usergroup"])
    },
    created(){
        this.getTicket();
        this.getTenants();
    },
    watch: {
        ticketId(newId, oldId) {
            if(newId != oldId) {
                this.getTicket();
                this.$root.$refs.SuperSearch.setClosed();
            }
        },
        newCommentTekst: function() {
            this.$refs.newComment.style.height = "auto";
            this.$nextTick(() => {
                this.$refs.newComment.style.height = this.$refs.newComment.scrollHeight + 'px';
            })
        }
    },
    methods: {
        getTicket() {

            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;

            var imageurls = [];
            var linkurls = [];
            
            function getImages(string) {
                const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
                const images = [];
                    let img;
                    while ((img = imgRex.exec(string))) {
                        images.push(img[1]);
                    }
                return images;
            }

            function getLinks(string) {
                const aRex = /<a .*?href="(.*?)"[^>]+>/g;
                const links = [];
                    let a;
                    while ((a = aRex.exec(string))) {
                        links.push(a[1]);
                    }
                return links;
            }

            let app = this;
            
            this.$axios.get(cacheBuster(this.apiUrl+'/wp-json/paris/v1/issue/'+this.ticketId), {
                params: {
                    fields: 'created,summary,priority,status,components,creator,customfield_10002,description,reporter,customfield_10010,customfield_10136,customfield_10419,customfield_10420,customfield_10421,customfield_10422,customfield_10423,customfield_10424,customfield_10425,customfield_10426,attachment,customfield_10010,updated',
                    expand: 'renderedFields'
                }
            }).then(function (response) {         

                imageurls = getImages(response.data.renderedFields.description);

                imageurls.forEach(element => {

                    if(element.includes("/thumbnail/")) {
                        
                        var attachmentId = element.match(/(?<=\/thumbnail\/).[0-9]+/g);
                        if(attachmentId == null) {
                            return;
                        }

                        app.$axios.get(app.apiUrl+'/wp-json/paris/v1/attachment/content/'+attachmentId[0]).then(function (imgresponse) {

                            response.data.renderedFields.description = response.data.renderedFields.description.replace(element, imgresponse.data);
                            
                        }); 

                    } else {
                        // console.log('no');
                    }
                   
                });

                
                linkurls = getLinks(response.data.renderedFields.description);

                // console.log(linkurls);

                linkurls.forEach(element => {

                    console.log(element);

                    if(element.includes("/attachment/")) {
                        
                        var attachmentId = element.match(/(?<=\/attachment\/).[0-9]+/g);
                        if(attachmentId == null) {
                            return;
                        }

                        app.$axios.get(app.apiUrl+'/wp-json/paris/v1/attachment/content/'+attachmentId[0]).then(function (linkresponse) {

                            response.data.renderedFields.description = response.data.renderedFields.description.replace(element, linkresponse.data);
                            response.data.renderedFields.description = response.data.renderedFields.description.replace('data-attachment-name', 'download');
                            
                        }); 

                    } else {
                        // console.log('no');
                        return;
                    }
                   
                });

                response.data.renderedFields.description = app.removeEmptyTags(app.stripLinks(app.removeImages(response.data.renderedFields.description)));
                response.data.renderedFields.customfield_10136 = app.htmlDecode(response.data.renderedFields.customfield_10136);

                app.ticket = response.data;

                app.getComments();

                // console.log(app.ticket.fields.attachment);

                // Klant vullen met een streepje als je niet in mps zit en het klant veld leeg is in de ticket
                if(app.ticket.renderedFields.customfield_10136 == '' && app.usergroup.acf.staat_klant_in_mps == false) {
                    app.ticket.renderedFields.customfield_10136 = {
                        name: '-'
                    }
                }

            });
        },
        getTenants() {

            this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/mps/gettoken').then(response => {
                var token = response.data.Token;
                // console.log(token.AccessToken);
                this.$store.commit('setmpsAccesstoken', token.AccessToken);
                // this.$store.commit('setmpsRefreshtoken', response.data.Token.AccessToken);

                this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/mps/getklantoptions', {
                    params: {
                        accessToken: this.mpsAccesstoken,
                        partner: this.usergroup.title.rendered
                    }
                }).then(response => {

                    this.tenantOptions = response.data;

                })
            })

        },
        saveTenant() {

            // console.log(this.usergroup.acf.ticket_systeem);

            // Deze code aanzetten als de klant weer aangepast moet kunnen worden
            let app = this;
            
            this.showKlantEdit = !this.showKlantEdit;

            this.$axios.put(this.apiUrl+'/wp-json/paris/v1/issue/'+this.ticketId, {
                fields: {
                    customfield_10136: ""+this.editKlant.name+""
                }
            }).then(function() {
                //console.log(response);
                app.getTicket();
            }).catch(function (error) {
                console.log(error);
            });

        },
        getAttachment(attachmentId,filename) {

            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;
            
            this.$axios.get(cacheBuster(this.apiUrl+'/wp-json/paris/v1/attachment/content/'+attachmentId)).then(function (response) {

                console.log(response);

                const url = response.data;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            });

        },
        getComments() {

            let app = this;

            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;

            this.$axios.get(cacheBuster(this.apiUrl+'/wp-json/paris/v1/issue/'+this.ticketId+'/comment'), {
                params: {
                    maxresults: 50,
                    expand: 'renderedBody'
                }
            }).then(function (response) {

                var arr = response.data.comments;
                var i;
                for (i = arr.length - 1; i >= 0; i -= 1) {
                    if (arr[i].jsdPublic === false) {
                        arr.splice(i, 1);
                    }
                }

                app.ticketComments = arr;                

            });

        },
        transformComment(content) {

            if(content.indexOf('bijlage(') > -1) {


                var regExp = /\((.*)\)/;
                var matches = regExp.exec(content);

                //Fix voor als bijlage(bijlagenummer) niet word afgesloten met een )
                if(matches == null) {

                    regExp = /\((.*),/;
                    matches = regExp.exec(content);

                }

                var variables = matches[1];
                var array = variables.split(',');
                var id = array[0];
                var filename = array[1];

                var link = '<p class="bijlage" attId="'+id+'" attName="'+filename+'">'+filename+'</p>';

                // console.log(content);

                content = content.replace(/bijlage\((.*)\)/, "");
                content = content.replace("'", "");

                return content + " <p class='filemsg'>Download bijlage:</p> " + link;
            } else {
                return content;
            }

        },
        handleBijlageClick(e) {
            const elt = e.target.closest(".bijlage");
            if (elt) {
                this.getAttachment(elt.getAttribute('attId'),elt.getAttribute('attName'));
            }
        },
        addComment() {
            let app = this;

            app.fileUploadVisible = false;

            // this.uploadFiles = this.$refs.uploader.files;

            var commentTekst = app.newCommentTekst;
            app.newCommentTekst = '';

            if(commentTekst == '' && app.uploadFiles.length > 0) {
                commentTekst = 'Bijlage toegevoegd:\n';

                for (var i = 0; i < app.uploadFiles.length; i++) {
                    commentTekst += '- '+app.uploadFiles[i].name+'\n';
                }
            }

            this.$axios.post(this.apiUrl+'/wp-json/paris/v1/issue/'+this.ticketId+'/comment', {
                body: ""+commentTekst+"",
                // "transition": {
                //     "id": "211"
                // }
            })
            .then(function (response) {

                var postID = response.data.id;

                var formData = new FormData();
                            
                if(app.uploadFiles.length > 0) {
                    
                    for (var i = 0; i < app.uploadFiles.length; i++) {
                        formData.append("file-"+i, app.uploadFiles[i]);
                    }
                
                    app.$axios.post( app.apiUrl+'/wp-json/paris/v1/issue/'+app.ticketId+'/attachments', formData, { headers: {'Content-Type' : 'multipart/form-data'}})
                    .then(response => {

                        app.uploadFiles = [];

                        commentTekst += " ";
                        commentTekst += "bijlage("+response.data[0].id+","+response.data[0].filename+")'";
                        app.$axios.put(app.apiUrl+'/wp-json/paris/v1/issue/'+app.ticketId+'/comment/'+postID, {
                            body: ""+commentTekst+"",
                        }).then(function() {

                            app.getTicket();
                            
                        });

                    });

                }

                // app.newCommentTekst = '';

                
                app.getComments();

            });

        },
        showFileUpload() {
            this.fileUploadVisible = !this.fileUploadVisible;
        },
        closeFileUpload() {
            let app = this;
            setTimeout(function(){
                app.fileUploadVisible = !app.fileUploadVisible;
            }, 700);
        },
        handleFilePondInit: function () {
            console.log("FilePond has initialized");
            // FilePond instance methods are available on `this.$refs.pond`
        },
        pushToUploads(files) {
            this.uploadFiles = files.map(files => files.file);
        },
        htmlDecode(input) {
            var doc = new DOMParser().parseFromString(input, "text/html");
            return doc.documentElement.textContent;
        },
        removeImages(input) {
            input = input.replace(/<img[^>]*>/g,"");
            return input;
        },
        stripLinks(input) {
            var re = /<a\s.*?href=["'](.*?)["']*?>(.*?)<\/a>/g;
            var str = input;
            // var subst = '$2';
            var result = str.replace(re, '');
            return result;
        },
        removeEmptyTags(input) {
            // console.log(input);
            input = input.replace(/&lt;&gt;/g,"");
            return input;
        },
        changeTab(index) {
            this.selectedTab = index;
            if(index == 1) {
                document.documentElement.style.setProperty('--commentFormHeight', '11rem');

                // var app = this;
                // setTimeout(function(){
                //     app.$refs.commentscroll.$el.scrollTop = app.$refs.commentscroll.$el.scrollHeight;
                // }, 520);

            } else {
                document.documentElement.style.setProperty('--commentFormHeight', '2rem');
            }
        },
    }
}
</script>