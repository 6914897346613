<template>
    
    <div class="w-full h-full pr-8 mt-8 mb-16 overflow-y-scroll">
        
        <form @submit="submitNewTicket" class="flex flex-col w-full mb-2" v-if="!loading">

            <div v-for="(layer, index) in formdata" :key="index" class="w-full h-full pl-2 pb-5 justify-center">

                <div v-for="(field, findex) in layer.fields" :key="findex" class="w-full">

                    <div v-if="field.layer == 1" v-show="showLayer == 1" class="flex flex-col justify-center items-center mt-40">

                        <h3 class="text-lg font-bold">Hoe kunnen wij jou vandaag verder helpen?</h3>
                        <p class="text-sm text-greyghost mb-8">Selecteer een tickettype om verder te gaan</p>
                        
                        <div id="keuze_ticketsoort" class="flex flex-row w-full justify-between items-center">
                            <div v-for="option in field.options.slice(1,3)" :key="option.value" class="usergroup-button flex flex-col w-[48%] xl:w-5/12 p-4 border-[1px] border-greypunch rounded-xl text-center hover:cursor-pointer hover:border-dstnygreen min-h-[300px] transition-all" @click="changeLayer(option.open_layer_nr,option.value)">
                                <img src="@/assets/img/ticketvraag.png" v-if="option.value == 'Ik heb een vraag'" class="mb-5" />
                                <img src="@/assets/img/ticketincident.png" v-if="option.value == 'Ik wil een incident melden'" class="mb-5" />
                                <span class="text-dstnygreen font-semibold usergroup_txt">{{option.value}}</span>
                                <p v-if="option.value == 'Ik heb een vraag'">Heb je een geweldige vraag over een specifiek onderwerp?</p>
                                <p v-if="option.value == 'Ik wil een incident melden'">Ben jij tegen ongeregeldheden aangelopen die je hinder opleveren?</p>
                            </div>
                        </div>

                    </div>


                    <div v-if="field.layer == 2" v-show="showLayer == 2" class="flex flex-col justify-start mb-5">

                        <label class="font-bold mb-3">{{field.label}}</label>
                        <input v-model="layer_2[findex]" type="text" class="usergroup-input border-[1px] border-greypunch rounded-md mb-5 focus:pl-8 transition-all" v-if="field.type == 'text'" />
                        <v-select v-model="layer_2[findex]" v-if="field.type == 'select'"></v-select>
                        <!-- <QuillEditor v-model="layer_2[findex]" theme="snow" toolbar="minimal" v-if="field.type == 'textarea'" /> -->
                        <textarea v-model="layer_2[findex]" class="usergroup-input mb-5 border-[1px] border-greypunch text-greypunch focus:pl-8 transition-all" rows="5" :placeholder="setPlaceHolder(field.label)" v-if="field.type == 'textarea'"></textarea>
                        <file-pond
                            :name="'input_'+findex"
                            ref="pondfiles"
                            label-idle="Drop files here..."
                            v-bind:allow-multiple="true"
                            v-bind:files="uploadFiles"
                            v-on:init="handleFilePondInit"
                            :allowImagePreview="false"
                            v-if="field.type == 'file'"
                        />
                        <div class="uploadedfiles flex flex-row flex-wrap gap-4" v-if="field.type == 'file'">
                            <div class="relative flex flex-col p-4 w-2/12 border-[1px] border-greypunch rounded-md mb-5" v-for="(file, fileIndex) in uploadFiles" :key="fileIndex">
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/jpg.svg" v-if="file.type == 'image/jpeg'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/png.svg" v-if="file.type == 'image/png'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/gif.svg" v-if="file.type == 'image/gif'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/svg.svg" v-if="file.type == 'image/svg+xml'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xls.svg" v-if="file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/ppt.svg" v-if="file.type == 'application/vnd.ms-powerpoint' || file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/doc.svg" v-if="file.type == 'application/msword' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xml.svg" v-if="file.type == 'application/xml' || file.type == 'text/xml'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/json.svg" v-if="file.type == 'application/json'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp4.svg" v-if="file.type == 'video/mp4'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp3.svg" v-if="file.type == 'audio/mpeg'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/wmv.svg" v-if="file.type == 'video/x-ms-wmv'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mail.svg" v-if="file.type == 'application/vnd.ms-outlook'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/pdf.svg" v-if="file.type == 'application/pdf'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/csv.svg" v-if="file.type == 'text/csv'" />
                                <span class="w-full text-center text-xs text-greyghost">{{ file.name }}</span>
                                <span class="absolute -right-2 -top-2 bg-[#fff] flex justify-center items-center rounded-full w-4 h-4 border-[1px] border-greypunch hover:cursor-pointer" @click="removeUploadedFile(fileIndex)">
                                    <img class="w-[6px] h-[6px] m-0 p-0 rounded-none" src="@/assets/img/close.svg" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="field.layer == 3" v-show="showLayer == 3" class="flex flex-col justify-start mb-5">

                        <label class="w-full font-bold mb-3" v-if="this.usergroup.acf.staat_klant_in_mps == false && field.label != 'Klant'">{{field.label}}</label>
                        <label class="w-full font-bold mb-3" v-if="this.usergroup.acf.staat_klant_in_mps == true">{{field.label}}</label>
                        <input v-model="layer_3[findex]" type="text" class="usergroup-input border-[1px] rounded-md mb-2 focus:pl-8 transition-all" :class="(layer_3_validations[findex] == true) ? '!border-[red]' : 'border-greypunch'" v-if="field.type == 'text'" @blur="updateValidations3(layer_3[findex],findex)" />
                        <v-select required v-model="layer_3[findex]" :options="tenantOptions" label="name" class="usergroup-input rounded-md" v-if="field.label == 'Klant' && showLayer == 3 && this.usergroup.acf.staat_klant_in_mps == true && this.usergroup.acf.vrij_klant_aanmaken == true" placeholder="Kies een klant, of voer een klantnaam in als de klant die je zoekt er niet tussenstaat" @search:blur="() => updateValidations3(layer_3[findex],findex)" taggable>
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_3[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" class="mr-4">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <v-select required v-model="layer_3[findex]" :options="tenantOptions" label="name" class="usergroup-input rounded-md" v-if="field.label == 'Klant' && showLayer == 3 && this.usergroup.acf.staat_klant_in_mps == true && this.usergroup.acf.vrij_klant_aanmaken == false" placeholder="Kies een klant" @search:blur="() => updateValidations3(layer_3[findex],findex)">
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_3[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" class="mr-4">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <v-select required v-model="layer_3[findex]" :options="labelOptions" label="label" class="usergroup-input rounded-md" v-if="field.label == 'Label' && showLayer == 3" placeholder="Kies een label" @search:blur="() => updateValidations3(layer_3[findex],findex)">
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.35577 12.9615C6.35577 12.3402 6.85945 11.8365 7.48077 11.8365H8.51923C9.14055 11.8365 9.64423 12.3402 9.64423 12.9615V14C9.64423 14.6213 9.14055 15.125 8.51923 15.125H7.48077C6.85945 15.125 6.35577 14.6213 6.35577 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M6.35577 7.48077C6.35577 6.85945 6.85945 6.35577 7.48077 6.35577H8.51923C9.14055 6.35577 9.64423 6.85945 9.64423 7.48077V8.51923C9.64423 9.14055 9.14055 9.64423 8.51923 9.64423H7.48077C6.85945 9.64423 6.35577 9.14055 6.35577 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M0.875 12.9615C0.875 12.3402 1.37868 11.8365 2 11.8365H3.03846C3.65978 11.8365 4.16346 12.3402 4.16346 12.9615V14C4.16346 14.6213 3.65978 15.125 3.03846 15.125H2C1.37868 15.125 0.875 14.6213 0.875 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M11.8365 12.9615C11.8365 12.3402 12.3402 11.8365 12.9615 11.8365H14C14.6213 11.8365 15.125 12.3402 15.125 12.9615V14C15.125 14.6213 14.6213 15.125 14 15.125H12.9615C12.3402 15.125 11.8365 14.6213 11.8365 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M11.8365 7.48077C11.8365 6.85945 12.3402 6.35577 12.9615 6.35577H14C14.6213 6.35577 15.125 6.85945 15.125 7.48077V8.51923C15.125 9.14055 14.6213 9.64423 14 9.64423H12.9615C12.3402 9.64423 11.8365 9.14055 11.8365 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M6.35577 2C6.35577 1.37868 6.85945 0.875 7.48077 0.875H8.51923C9.14055 0.875 9.64423 1.37868 9.64423 2V3.03846C9.64423 3.65978 9.14055 4.16346 8.51923 4.16346H7.48077C6.85945 4.16346 6.35577 3.65978 6.35577 3.03846V2Z" stroke="black" stroke-linecap="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_3[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" class="mr-4">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <v-select v-model="layer_3[findex]" v-if="field.type == 'select' && field.label !== 'Klant' && field.label !== 'Label'"></v-select>
                        <!-- <QuillEditor v-model:content="layer_3[findex]" contentType="text" theme="snow" toolbar="minimal" class="mb-5" v-if="field.type == 'textarea'" /> -->
                        <textarea v-model="layer_3[findex]" class="usergroup-input mb-5 border-[1px] border-greypunch focus:pl-8 transition-all" :class="(layer_3_validations[findex] == true) ? '!border-[red]' : 'border-greypunch'" rows="5" :placeholder="setPlaceHolder(field.label)" v-if="field.type == 'textarea'"></textarea>
                        <file-pond
                            :name="'input_'+findex"
                            class="mb-5"
                            ref="pondfiles"
                            label-idle="Drop files here..."
                            :allow-multiple="true"
                            :allowImagePreview="false"
                            :files="myFiles"
                            server="https://httpbin.org/post"
                            instant-upload="false"
                            @init="handleFilePondInit"
                            v-on:updatefiles="pushToUploads"
                            v-if="field.type == 'file'"
                        />
                        <div class="uploadedfiles flex flex-row flex-wrap gap-4" v-if="field.type == 'file'">
                            <div class="relative flex flex-col p-4 w-2/12 border-[1px] border-greypunch rounded-md mb-5" v-for="(file, fileIndex) in uploadFiles" :key="fileIndex">
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/jpg.svg" v-if="file.type == 'image/jpeg'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/png.svg" v-if="file.type == 'image/png'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/gif.svg" v-if="file.type == 'image/gif'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/svg.svg" v-if="file.type == 'image/svg+xml'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xls.svg" v-if="file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/ppt.svg" v-if="file.type == 'application/vnd.ms-powerpoint' || file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/doc.svg" v-if="file.type == 'application/msword' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" />   
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xml.svg" v-if="file.type == 'application/xml' || file.type == 'text/xml'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/json.svg" v-if="file.type == 'application/json'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp4.svg" v-if="file.type == 'video/mp4'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp3.svg" v-if="file.type == 'audio/mpeg'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/wmv.svg" v-if="file.type == 'video/x-ms-wmv'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mail.svg" v-if="file.type == 'application/vnd.ms-outlook'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/pdf.svg" v-if="file.type == 'application/pdf'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/csv.svg" v-if="file.type == 'text/csv'" />
                                <span class="w-full text-center text-xs text-greyghost">{{ file.name }}</span>
                                <span class="absolute -right-2 -top-2 bg-[#fff] flex justify-center items-center rounded-full w-4 h-4 border-[1px] border-greypunch hover:cursor-pointer" @click="removeUploadedFile(fileIndex)">
                                    <img class="w-[6px] h-[6px] m-0 p-0 rounded-none" src="@/assets/img/close.svg" />
                                </span>
                            </div>
                        </div>
                        <span class="text-[red] text-sm">{{ showErrorMessage(layer_3_validations[findex]) }}</span>

                    </div>

                    <div v-if="field.layer == 4" v-show="showLayer == 4" class="flex flex-col justify-start mb-5">

                        <label class="w-full font-bold mb-3" v-if="this.usergroup.acf.staat_klant_in_mps == false && field.label != 'Klant'">{{field.label}}</label>
                        <label class="w-full font-bold mb-3" v-if="this.usergroup.acf.staat_klant_in_mps == true">{{field.label}}</label>
                        <input v-model="layer_4[findex]" type="text" class="usergroup-input border-[1px] rounded-md mb-5 focus:pl-8 transition-all" v-if="field.type == 'text'" :class="(layer_4_validations[findex] == true) ? '!border-[red]' : 'border-greypunch'" @blur="updateValidations4(layer_4[findex],findex)" />
                        <v-select v-model="layer_4[findex]" :options="tenantOptions" label="name" class="usergroup-input rounded-md" v-if="field.label == 'Klant' && showLayer == 4  && this.usergroup.acf.staat_klant_in_mps == true && this.usergroup.acf.vrij_klant_aanmaken == true" placeholder="Kies een klant, of voer een klantnaam in als de klant die je zoekt er niet tussenstaat" @search:blur="() => updateValidations4(layer_4[findex],findex)" taggable>
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_4[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <v-select v-model="layer_4[findex]" :options="tenantOptions" label="name" class="usergroup-input rounded-md" v-if="field.label == 'Klant' && showLayer == 4  && this.usergroup.acf.staat_klant_in_mps == true && this.usergroup.acf.vrij_klant_aanmaken == false" placeholder="Kies een klant" @search:blur="() => updateValidations4(layer_4[findex],findex)">
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0.75L1 4.46006V4.87229L1.3913 5.69675H18.6087L19 4.87229V4.46006L10 0.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.1304 14.7661V9.4071C13.1304 9.07911 13.254 8.76455 13.4742 8.53263C13.6944 8.3007 13.9929 8.17041 14.3043 8.17041C14.6156 8.17041 14.9142 8.3007 15.1344 8.53263C15.3545 8.76455 15.4782 9.07911 15.4782 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.82617 14.7661V9.4071C8.82617 9.07911 8.94985 8.76455 9.17 8.53263C9.39015 8.3007 9.68874 8.17041 10.0001 8.17041C10.3114 8.17041 10.61 8.3007 10.8302 8.53263C11.0503 8.76455 11.174 9.07911 11.174 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.52148 14.7661V9.4071C4.52148 9.07911 4.64516 8.76455 4.86531 8.53263C5.08547 8.3007 5.38406 8.17041 5.6954 8.17041V8.17041C6.00674 8.17041 6.30533 8.3007 6.52548 8.53263C6.74563 8.76455 6.86931 9.07911 6.86931 9.4071V14.7661" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.78271 6.93359V7.75805L2.56532 8.17028V15.1782" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.4346 15.1782L17.4346 8.17028L18.2172 7.75805V6.93359" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 15.1782H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.39111 17.25H18.6085" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_4[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <v-select v-model="layer_4[findex]" :options="labelOptions" label="label" class="usergroup-input rounded-md" v-if="field.label == 'Label' && showLayer == 4" placeholder="Kies een label" @search:blur="() => updateValidations4(layer_4[findex],findex)">
                            <template #search="{ attributes, events }">
                                <svg width="16" height="16" class="ml-4 mr-2 place-self-center" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.35577 12.9615C6.35577 12.3402 6.85945 11.8365 7.48077 11.8365H8.51923C9.14055 11.8365 9.64423 12.3402 9.64423 12.9615V14C9.64423 14.6213 9.14055 15.125 8.51923 15.125H7.48077C6.85945 15.125 6.35577 14.6213 6.35577 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M6.35577 7.48077C6.35577 6.85945 6.85945 6.35577 7.48077 6.35577H8.51923C9.14055 6.35577 9.64423 6.85945 9.64423 7.48077V8.51923C9.64423 9.14055 9.14055 9.64423 8.51923 9.64423H7.48077C6.85945 9.64423 6.35577 9.14055 6.35577 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M0.875 12.9615C0.875 12.3402 1.37868 11.8365 2 11.8365H3.03846C3.65978 11.8365 4.16346 12.3402 4.16346 12.9615V14C4.16346 14.6213 3.65978 15.125 3.03846 15.125H2C1.37868 15.125 0.875 14.6213 0.875 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M11.8365 12.9615C11.8365 12.3402 12.3402 11.8365 12.9615 11.8365H14C14.6213 11.8365 15.125 12.3402 15.125 12.9615V14C15.125 14.6213 14.6213 15.125 14 15.125H12.9615C12.3402 15.125 11.8365 14.6213 11.8365 14V12.9615Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M11.8365 7.48077C11.8365 6.85945 12.3402 6.35577 12.9615 6.35577H14C14.6213 6.35577 15.125 6.85945 15.125 7.48077V8.51923C15.125 9.14055 14.6213 9.64423 14 9.64423H12.9615C12.3402 9.64423 11.8365 9.14055 11.8365 8.51923V7.48077Z" stroke="black" stroke-linecap="round"/>
                                    <path d="M6.35577 2C6.35577 1.37868 6.85945 0.875 7.48077 0.875H8.51923C9.14055 0.875 9.64423 1.37868 9.64423 2V3.03846C9.64423 3.65978 9.14055 4.16346 8.51923 4.16346H7.48077C6.85945 4.16346 6.35577 3.65978 6.35577 3.03846V2Z" stroke="black" stroke-linecap="round"/>
                                </svg>
                                <input class="vs__search" v-bind="attributes" v-on="events" :required="!layer_4[findex]">                
                            </template>
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes">
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 0.625001L8 7.375L1.25 0.625001" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </template>
                        </v-select>
                        <!-- <v-select v-model="layer_4[findex]" :options="tenantOptions" label="name" v-if="field.label == 'Klant'"></v-select> -->
                        <!-- <v-select v-model="layer_4[findex]" :options="labelOptions" label="label" v-if="field.label == 'Label'"></v-select> -->
                        <v-select v-model="layer_4[findex]" v-if="field.type == 'select' && field.label !== 'Klant' && field.label !== 'Label'"></v-select>
                        <!-- <QuillEditor v-model:content="layer_4[findex]" contentType="text" theme="snow" toolbar="minimal" class="mb-5" v-if="field.type == 'textarea'" /> -->
                        <textarea v-model="layer_4[findex]" class="usergroup-input mb-5 border-[1px] focus:pl-8 transition-all" rows="5" v-if="field.type == 'textarea'" :class="(layer_4_validations[findex] == true) ? '!border-[red]' : 'border-greypunch'" :placeholder="setPlaceHolder(field.label)"></textarea>
                        <file-pond
                            :name="'input_'+findex"
                            class="mb-5"
                            ref="pondfiles"
                            label-idle="Drop files here..."
                            :allow-multiple="true"
                            :allowImagePreview="false"
                            :files="myFiles"
                            server="https://httpbin.org/post"
                            instant-upload="false"
                            @init="handleFilePondInit"
                            v-on:updatefiles="pushToUploads"
                            v-if="field.type == 'file'"
                        />
                        <div class="uploadedfiles flex flex-row flex-wrap gap-4" v-if="field.type == 'file'">
                            <div class="relative flex flex-col p-4 w-2/12 border-[1px] border-greypunch rounded-md mb-5" v-for="(file, fileIndex) in uploadFiles" :key="fileIndex">
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/jpg.svg" v-if="file.type == 'image/jpeg'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/png.svg" v-if="file.type == 'image/png'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/gif.svg" v-if="file.type == 'image/gif'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/svg.svg" v-if="file.type == 'image/svg+xml'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xls.svg" v-if="file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/ppt.svg" v-if="file.type == 'application/vnd.ms-powerpoint' || file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/doc.svg" v-if="file.type == 'application/msword' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" />   
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/xml.svg" v-if="file.type == 'application/xml' || file.type == 'text/xml'" />                                
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/json.svg" v-if="file.type == 'application/json'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp4.svg" v-if="file.type == 'video/mp4'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mp3.svg" v-if="file.type == 'audio/mpeg'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/wmv.svg" v-if="file.type == 'video/x-ms-wmv'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/mail.svg" v-if="file.type == 'application/vnd.ms-outlook'" /> 
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/pdf.svg" v-if="file.type == 'application/pdf'" />
                                <img class="w-[20px] mx-auto my-0 mb-3 rounded-none" src="@/assets/img/csv.svg" v-if="file.type == 'text/csv'" />
                                <span class="w-full text-center text-xs text-greyghost">{{ file.name }}</span>
                                <span class="absolute -right-2 -top-2 bg-[#fff] flex justify-center items-center rounded-full w-4 h-4 border-[1px] border-greypunch hover:cursor-pointer" @click="removeUploadedFile(fileIndex)">
                                    <img class="w-[6px] h-[6px] m-0 p-0 rounded-none" src="@/assets/img/close.svg" />
                                </span>
                            </div>
                        </div>
                        <span class="text-[red] text-sm">{{ showErrorMessage(layer_4_validations[findex]) }}</span>

                    </div>
                        
                </div>

            </div>

            <div class="flex flex-row place-self-end items-center" v-show="showLayer == 3 || showLayer == 4">
                <a href="javascript:;" class="usergroup_txt mr-8" @click="stepBack()">Stap terug</a>
                <button id="verstuur_ticket" class="px-5 py-3 usergroup_bg text-[#ffffff] rounded-full">Verstuur ticket</button>
            </div>

        </form>

        <div class="flex flex-col w-full h-full justify-center items-center" v-if="loading">
            <span class="text-xl font-bold">Super, je ticket is aangemaakt 🎉</span>
            <span class="text-base text-greyghost mb-8">Je wordt zo doorgestuurd naar je ticket</span>
            <img src="@/assets/img/success.gif" width="400" />
        </div>

    </div>

</template>
<script>
import vSelect from 'vue-select'

// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';

import vueFilePond, { setOptions } from "vue-filepond";
// // import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// import { PerfectScrollbar } from 'vue3-perfect-scrollbar';


setOptions({
    credits: false,
});
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import { mapGetters } from "vuex";

export default {
    name: 'CreateTicketForm',
    components: {
    vSelect,
    // QuillEditor,
    FilePond,
    // PerfectScrollbar
},
    data() {
        return {
            formdata: [],
            allFields: [],
            fields: [],
            showLayer: 1,
            tenantOptions: [],
            labelOptions: [],
            //Dit is keuze tickettype
            layer_1: [],
            //Deprecated Change request
            layer_2: [],
            //Dit is Incident melden
            layer_3: [],
            //Dit is Stel een vraag
            layer_4: [],
            editorOptions: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered'}]
                    ]
                }
            },
            myFiles: null,
            uploadFiles: [],
            loading: false,
            aanmakenopKlantID: null,
            selectedTicketType: 0,
            //Loading timeout lottie animation
            lottieLoadingTimeout: 4000,
            createTicketBoolean: false,
            showScroll: false,
            layer_3_validations: [],
            layer_4_validations: [],
        }
    },
    computed: {
        ...mapGetters(["mpsAccesstoken","usergroup","user","useremail"])
    },
    created() {

        // console.log(this.usergroup.acf.jira_partner[0]);
        const cacheBuster = (url) => `${url}?cb=${Date.now()}`;

        this.layer_1 = Array(20).fill("");
        this.layer_2 = Array(20).fill("");
        this.layer_3 = Array(20).fill("");
        this.layer_4 = Array(20).fill("");
            
        this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/ticketform').then(response => {
            this.formdata = response.data.layers
        })

        this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/mps/gettoken').then(response => {

            var token = response.data.Token;
            // console.log(token.AccessToken);
            this.$store.commit('setmpsAccesstoken', token.AccessToken);
            // this.$store.commit('setmpsRefreshtoken', response.data.Token.AccessToken);

            this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/mps/getklantoptions', {
                params: {
                    accessToken: this.mpsAccesstoken,
                    partner: this.usergroup.acf.jira_partner[0]
                }
            }).then(response => {

                this.tenantOptions = response.data;

            })
        })

        this.$axios.get(this.apiUrl+'/wp-json/paris/v1/front-end/mps/getlabels', {
            params: {
                usergroup_id: this.usergroup.id
            }
        }).then(response => {

            this.labelOptions = response.data;
        })

        var app = this;
        this.$axios.get(cacheBuster(this.apiUrl+'/wp-json/paris/v1/servicedesk/organization')).then(function (response) {

            var organisaties = response.data.values;

            organisaties.forEach((element) => {
                
                if(app.usergroup.title.rendered == "Dstny") {
                    if(element.name == "Soluno NL") {
                        app.aanmakenopKlantID = element.id;
                    }            
                } else if(app.usergroup.title.rendered == "Odido") {
                    if(element.name == "Odido (CBO)") {
                        app.aanmakenopKlantID = element.id;
                    }            
                } else {
                    if(element.name == app.usergroup.title.rendered || element.name == app.usergroup.acf.jira_partner[0]) {
                        app.aanmakenopKlantID = element.id;
                    }   
                }
                
            });

        });
    },
    methods: {
        updateValidations3(val, index) {
            if(val == '') {
                this.layer_3_validations[index] = true;
            } else {
                this.layer_3_validations[index] = false;
            }
        },
        updateValidations4(val, index) {
            if(val == '') {
                this.layer_4_validations[index] = true;
            } else {
                this.layer_4_validations[index] = false;
            }
        },
        showErrorMessage(val) {
            if(val == true) {
                return 'Dit veld is verplicht';
            }
        },
        handleAnimation: function (anim) {
            this.anim = anim;
        },
        changeFormData(input,method,array) {
            if(method == 'shift' && array == '') {
                input.shift();
            }

            if(method == 'push' && array == 'fields') {
                this.fields.push(input);
            }

            if(method == 'setarray' && array == 'allFields') {
                this.allFields = input;
            }
        },
        changeLayer(openlayer,curlayer) {

            if(openlayer == 1) {
                this.showScroll = false;
                document.documentElement.style.setProperty('--ticketProgress', '30%');
            } else if(openlayer > 1) {
                this.showScroll = true;
                document.documentElement.style.setProperty('--ticketProgress', '60%');
            }

            // console.log(openlayer);
            if(this.selectedTicketType == 0 || this.selectedTicketType == openlayer) {
                this.selectedTicketType = openlayer;
            } else {
                this.layer_1 = [];
                this.layer_2 = [];
                this.layer_3 = Array(20).fill("");
                this.layer_4 = Array(20).fill("");
                this.layer_3_validations = [];
                this.layer_4_validations = [];
                this.selectedTicketType = openlayer;
            }

            this.showLayer = openlayer;
            this.layer_1 = curlayer;
        },
        stepBack() {
            this.showLayer = 1;
            this.layer_3 = Array(20).fill("");
            this.layer_4 = Array(20).fill("");
            this.layer_3_validations = [];
            this.layer_4_validations = [];
            document.documentElement.style.setProperty('--ticketProgress', '30%');
        },
        handleFilePondInit: function () {
            console.log("FilePond has initialized");
            // FilePond instance methods are available on `this.$refs.pond`
        },
        onAddFile(error, file) {
            console.log(file);
        },
        pushToVar(field, value) {
            console.log(field.value);
            console.log(value);
        },
        pushToUploads(files) {
            this.uploadFiles = files.map(files => files.file);
            console.log(this.uploadFiles);
        },  
        removeUploadedFile(index) {
            this.$refs.pondfiles[0].removeFile(index);
            this.$refs.pondfiles[1].removeFile(index);
            this.$refs.pondfiles[2].removeFile(index);
        },
        toTicketDetails(issueId,key,type,samenvatting) {
            // this.$root.$refs.RightSidebar.sendTicketIdToDetailComponent(issueId,key,type);
            this.$root.$refs.RightSidebar.sendTicketIdToDetailComponent(issueId,key,type);
            this.$root.$refs.RightSidebar.setTicketTitle(samenvatting);
            // this.$root.$refs.view.updateTableData();
            // this.$root.$refs.alletickets.updateTableData();
            // this.$root.$refs.ticketarchief.updateTableData();
            // this.$root.$refs.RightSidebar.toggleSideBar();
        },
        setPlaceHolder(label) {
            if(label == 'Wat probeert de klant te bereiken?') {
                return 'Geef een beknopte beschrijving van het doel dat je probeert te bereiken met deze melding.';
            } else if(label == 'Beschrijf welke stappen je onderneemt en waar het mist gaat.') {
                return 'Vertel ons welke specifieke stappen je hebt genomen voordat het probleem zich voordeed. Waar en op welk punt ging er iets mis?';
            } else if(label == 'Welke zaken heb je ondernomen om het probleem zelf op te lossen?') {
                return 'Heb je al pogingen ondernomen om het probleem zelf op te lossen? Zo ja, deel dan de stappen die je hebt genomen.';
            } else if(label == 'Heeft een wijziging plaats gevonden of zijn er andere zaken die je opvallen?') {
                return 'Zijn er recentelijk wijzigingen doorgevoerd in het systeem of andere opvallende gebeurtenissen die van invloed kunnen zijn op dit incident?';
            } else if(label == 'Indien van toepassing graag een voorbeeld waarbij het mis gaat') {
                return 'Als je een specifiek voorbeeld kunt geven waarbij het probleem zich heeft voorgedaan, helpt dat ons om het incident beter te begrijpen. Beschrijf het voorbeeld in detail.';
            } else if(label == 'Stel hier je vraag') {
                return 'Geef een duidelijke en beknopte beschrijving van je vraag. Hoe meer details je verstrekt, hoe gemakkelijker het voor ons is om je te helpen.';
            } else if(label == 'Waarvoor heb je het nodig? Dit helpt ons om mee te denken mocht het niet mogelijk zijn') {
                return 'Vertel ons waarom je deze vraag stelt en waarvoor je de informatie nodig hebt. Als er bepaalde behoeften of doelen zijn die je wilt bereiken, laat het ons dan weten.';
            } else if(label == 'Wie kunnen we benaderen voor additionele informatie?') {
                return 'Als er andere mensen of bronnen zijn die we kunnen raadplegen om je vraag beter te begrijpen of te beantwoorden, vermeld dan hun namen of contactgegevens';
            }

        },
        submitNewTicket(e) {
            e.preventDefault();

            //Filter alle lege waardes uit de tabel
            // this.layer_2 = this.layer_2.filter(n => n);
            // this.layer_3 = this.layer_3.filter(n => n);
            // this.layer_4 = this.layer_4.filter(n => n);

            // if(this.layer_3[3] == '') {
            //     this.layer_3.splice(3, 1);
            // }

            var formData = new FormData();

            for (var i = 0; i < this.uploadFiles.length; i++) {
                let file = this.uploadFiles[i];
                formData.append("files[" + i + "]", file);
            }

            var issueType = null;
            var klant = '';
            var label = '';
            var samenvatting = '';
            var description = '';
            var issueReporter = null;
            var reporterId = null;
            var reporterEmail = null;

            var watklantbereiken = '';
            var beschrijfstappen = '';
            var welkezakenondernomen = '';
            var heeftwijzigingplaatsgevonden = '';
            var indienvantoepassing = '';

            var steljevraag = '';
            var waarvoornodig = '';
            var wiebenaderen = '';

            // var uploadFiles = this.$refs.uploader[0].files;

            if(this.layer_1 == 'Change request') {

                console.log('test');

            } else if(this.layer_1 == 'Ik wil een incident melden') {

                issueType = 80;
                klant = this.layer_3[1];
                label = this.layer_3[2];

                console.log(this.layer_3[0]);
                
                if(klant.name == undefined && this.usergroup.acf.staat_klant_in_mps == false) {
                    klant = {
                        name: ''
                    };
                }
                
                if(this.layer_3[0] == '') {
                    this.layer_3_validations[0] = true;
                    return;
                } else {
                    samenvatting = this.layer_3[0].replace(/(<([^>]+)>)/gi, "");
                }
                
                this.layer_3.forEach((element, index) => {
                    
                    if(index == 0) {
                        description += '<b>Onderwerp:</b> ' + element+'\n';
                    } else  if(index == 1) {
                        description += '<b>Klant:</b> ' + element.name+'\n';
                    } else  if(index == 2) {
                        description += '<b>Label:</b> ' + element.label+'\n';
                    } else if(index == 4) {
                        //   watklantbereiken = element;
                        watklantbereiken = element.replace(/(<([^>]+)>)/gi, "");
                        description += '<b>Wat probeert de klant te bereiken?:</b> ' + element+'\n';
                    } else if(index == 5) {
                        //   beschrijfstappen = element;
                        beschrijfstappen = element.replace(/(<([^>]+)>)/gi, "");
                        description += '<b>Beschrijf welke stappen je onderneemt en waar het mist gaat.:</b> ' + element+'\n';
                    } else if(index == 6) {  
                        //   welkezakenondernomen = element;
                        welkezakenondernomen = element.replace(/(<([^>]+)>)/gi, "");
                        description += '<b>Welke zaken heb je ondernomen om het probleem zelf op te lossen?:</b> ' + element+'\n';
                    } else if(index == 7) {
                        //   heeftwijzigingplaatsgevonden = element;
                        heeftwijzigingplaatsgevonden = element.replace(/(<([^>]+)>)/gi, "");
                        description += '<b>Heeft een wijziging plaats gevonden of zijn er andere zaken die je opvallen?:</b> ' + element+'\n';
                    } else if(index == 8) {
                        //   indienvantoepassing = element;
                        indienvantoepassing = element.replace(/(<([^>]+)>)/gi, "");
                        description += '<b>Indien van toepassing graag een voorbeeld waarbij het mis gaat:</b> ' + element+'\n';
                    }

                });

            } else if(this.layer_1 == 'Ik heb een vraag') {

            // issueType = 10155;
                issueType = 264;
                klant = this.layer_4[1];
                label = this.layer_4[2];

                if(klant.name == undefined && this.usergroup.acf.staat_klant_in_mps == false) {
                    klant = {
                        name: ''
                    };
                }

                if(this.layer_4[0] == '') {
                    this.layer_4_validations[0] = true;
                    return;
                } else {
                    samenvatting = this.layer_4[0];
                }

                // samenvatting = this.layer_4[0]; //.replace(/(<([^>]+)>)/gi, "");
                this.layer_4.forEach((element, index) => {
                    // console.log(element)
                    if(index == 0) {
                        description += 'Onderwerp: ' + element+'\n';  
                    } else if(index == 1) {
                        description += 'Klant: ' + element.name+'\n';
                        // klant = element.name;
                    } else if(index == 2) {
                        description += 'Label: ' + element.label+'\n';  
                    } else if(index == 4) {
                        steljevraag = element.replace(/(<([^>]+)>)/gi, "");
                        description += 'Stel hier je vraag: ' + element+'\n';  
                    } else if(index == 5) {
                        waarvoornodig = element.replace(/(<([^>]+)>)/gi, "");
                        description += 'Waarvoor heb je het nodig? Dit helpt ons om mee te denken mocht het niet mogelijk zijn: ' + element+'\n';  
                    } else if(index == 6) {
                        wiebenaderen = element.replace(/(<([^>]+)>)/gi, "");
                        description += 'Wie kunnen we benaderen voor additionele informatie?: ' + element+'\n';  
                    } 
                });
            }

            var app = this;

            console.log(this.layer_4);

            this.$axios.get(this.apiUrl+'/wp-json/paris/v1/user/search', {
                params: {
                    query: this.useremail,
                    project: 'SNL'
                }
            }).then(function (response) {
                issueReporter = response.data;
                reporterId = issueReporter[0].accountId;
                reporterEmail = issueReporter[0].emailAddress;

                console.log(reporterEmail);

                var tmp = document.createElement("DIV");
                tmp.innerHTML = description;

                description = tmp.textContent;

                console.log(issueType);

                if(issueType == 264) {

                    if(app.layer_4_validations.includes(true)) {
                        console.log('Errors');
                        return;
                    }

                    if(app.layer_4_validations[1] == null && app.usergroup.acf.staat_klant_in_mps == true) {
                        console.log('Errors');
                        return;
                    }

                    if(app.layer_4_validations[2] == null) {
                        console.log('Errors');
                        return;
                    }

                    //Zet loading op true om animatie te laten zien
                    app.loading = true;

                    app.$axios.post(app.apiUrl+'/wp-json/paris/v1/servicedesk/request', {
                        raiseOnBehalfOf: ""+reporterId+"",
                        serviceDeskId: "4",
                        requestTypeId: ""+issueType+"",
                        requestFieldValues: {
                        summary: samenvatting,
                        customfield_10002: [
                            parseInt(app.aanmakenopKlantID)
                        ],
                        customfield_10136: ""+klant.name+"",
                        customfield_10424: ""+steljevraag+"",
                        customfield_10425: ""+waarvoornodig+"",
                        customfield_10426: ""+wiebenaderen+""
                        }
                    })
                    .then(function (response) {

                        if(response.errorMessage) {

                            console.log('error');

                        } else {
                        
                            if(response != null) {

                            var issueId = response.data.issueId;
                            var issueKey = response.data.issueKey;

                            app.$axios.put(app.apiUrl+'/wp-json/paris/v1/issue/'+issueId, {
                                fields: {
                                    description: description,
                                },
                                update: {
                                    components: [{

                                        set: [
                                        {
                                            name: label.label
                                        }
                                        ]

                                    }]
                                }
                            }).then(function (response) {
                                console.log(response);
                            }).catch(function (error) {
                                console.log(error);
                            });

                            //Onderwerp toevoegen aan google analytics
                            app.$gtm.trackEvent({
                                event: 'ticket_subject',
                                ticketSubject: issueType
                            });

                            var formData = new FormData();
                            
                            if(app.uploadFiles.length > 0) {
                                
                                for (var i = 0; i < app.uploadFiles.length; i++) {
                                    formData.append("file-"+i, app.uploadFiles[i]);
                                }
                            
                                app.$axios.post( app.apiUrl+'/wp-json/paris/v1/issue/'+issueId+'/attachments', formData, { headers: {'Content-Type' : 'multipart/form-data'}})
                                .then(response => {

                                    console.log(response);                                    
                                    // app.$router.push({ name: 'TicketView', params: { ticketId: issueId} });

                                    document.documentElement.style.setProperty('--ticketProgress', '100%');

                                    setTimeout(function(){
                                        // console.log(app.$root.$refs);
                                        app.$root.$refs.RightSidebar.createTicket(app.createTicketBoolean);
                                        app.toTicketDetails(issueId,issueKey,'Ik heb een vraag/ service request', samenvatting);
                                    }, app.lottieLoadingTimeout);

                                });

                            } else {

                                document.documentElement.style.setProperty('--ticketProgress', '100%');

                                setTimeout(function(){
                                    // console.log(app.$root.$refs);
                                    app.$root.$refs.RightSidebar.createTicket(app.createTicketBoolean);
                                    app.toTicketDetails(issueId,issueKey,'Ik heb een vraag/ service request', samenvatting);
                                }, app.lottieLoadingTimeout);

                                // app.$router.push({ name: 'TicketView', params: { ticketId: issueId, refreshTicket: 3000} });

                            }

                            }

                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                } else {                    

                    if(app.layer_3_validations.includes(true)) {
                        console.log('Errors');
                        return;
                    }

                    if(app.layer_3_validations[1] == null && app.usergroup.acf.staat_klant_in_mps == true) {
                        console.log('Errors');
                        return;
                    }

                    if(app.layer_3_validations[2] == null) {
                        console.log('Errors');
                        return;
                    }

                    //Zet loading op true om animatie te laten zien 
                    app.loading = true;

                    app.$axios.post(app.apiUrl+'/wp-json/paris/v1/servicedesk/request', {
                        raiseOnBehalfOf: ""+reporterId+"",
                        serviceDeskId: "4",
                        requestTypeId: ""+issueType+"",
                        requestFieldValues: {
                        summary: samenvatting,
                        customfield_10002: [
                            parseInt(app.aanmakenopKlantID)
                        ],
                        customfield_10136: ""+klant.name+"",
                        customfield_10419: ""+watklantbereiken+"",
                        customfield_10420: ""+beschrijfstappen+"",
                        customfield_10421: ""+welkezakenondernomen+"",
                        customfield_10422: ""+heeftwijzigingplaatsgevonden+"",
                        customfield_10423: ""+indienvantoepassing+"",
                        components: [{
                            name: label.label
                        }]
                        }
                    })
                    .then(function (response) {

                        if(response.errorMessage) {

                            console.log('error');

                        } else {
                        
                            if(response != null) {

                            var issueId = response.data.issueId;
                            var issueKey = response.data.issueKey;

                            app.$axios.put(app.apiUrl+'/wp-json/paris/v1/issue/'+issueId, {
                                fields: {
                                description: description,
                                },
                                update: {
                                components: [{

                                    set: [
                                    {
                                        name: label.label
                                    }
                                    ]

                                }]
                                }
                            }).then(function (response) {
                                console.log(response);
                            }).catch(function (error) {
                                console.log(error);
                            });

                            //Onderwerp toevoegen aan google analytics
                            app.$gtm.trackEvent({
                                event: 'ticket_subject',
                                ticketSubject: issueType
                            });

                            var formData = new FormData();
                            
                            if(app.uploadFiles.length > 0) {
                                
                                for (var i = 0; i < app.uploadFiles.length; i++) {
                                    formData.append("file-"+i, app.uploadFiles[i]);
                                }
                            
                                app.$axios.post( app.apiUrl+'/wp-json/paris/v1/issue/'+issueId+'/attachments', formData, { headers: {'Content-Type' : 'multipart/form-data'}})
                                .then(response => {

                                    console.log(response);
                                    
                                    // app.$router.push({ name: 'TicketView', params: { ticketId: issueId} });

                                    document.documentElement.style.setProperty('--ticketProgress', '100%');

                                    setTimeout(function(){
                                        app.$root.$refs.RightSidebar.createTicket(app.createTicketBoolean);
                                        app.toTicketDetails(issueId,issueKey,'none', samenvatting);

                                    }, app.lottieLoadingTimeout);

                                });

                            } else {

                                // app.$router.push({ name: 'TicketView', params: { ticketId: issueId, refreshTicket: 3000} });

                                document.documentElement.style.setProperty('--ticketProgress', '100%');

                                setTimeout(function(){
                                    app.$root.$refs.RightSidebar.createTicket(app.createTicketBoolean);
                                    app.toTicketDetails(issueId,issueKey,'none', samenvatting);
                                }, app.lottieLoadingTimeout);
                            }

                            }

                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                }
            });


            // console.log(this.layer_4);

            console.log(klant);
            console.log(label);
            console.log(samenvatting);
            console.log(description);
            console.log(watklantbereiken);
            console.log(beschrijfstappen);
            console.log(welkezakenondernomen);
            console.log(heeftwijzigingplaatsgevonden);
            console.log(indienvantoepassing);
            console.log(steljevraag);
            console.log(waarvoornodig);
            console.log(wiebenaderen);
            // console.log(uploadFiles);
            console.log(issueType);
            // console.log(this.uploadFiles);
            for (var pair of formData.entries()) {
                console.log(pair[1]); 
            }
        }
    },     
}
</script>
