<template>
    <div class="flex flex-row p-3 mb-8 top-16 items-center bg-cranberryjuice20 text-cranberryjuice border-cranberryjuice border-2 rounded-xl" :class="(relative == true) ? '' : 'fixed'">
        <svg class="mr-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="fill-cranberryjuice" fill-rule="evenodd" clip-rule="evenodd" d="M16 8C20.9706 8 25 12.0294 25 17C25 21.9706 20.9706 26 16 26C11.0294 26 7 21.9706 7 17C7 12.0294 11.0294 8 16 8ZM26 17C26 11.4772 21.5228 7 16 7C10.4772 7 6 11.4772 6 17C6 22.5228 10.4772 27 16 27C21.5228 27 26 22.5228 26 17ZM16 11.5C15.7238 11.5 15.5 11.7239 15.5 12V18.55C15.5 18.8262 15.7238 19.05 16 19.05C16.2761 19.05 16.5 18.8262 16.5 18.55V12C16.5 11.7239 16.2761 11.5 16 11.5ZM16 20.5C15.7238 20.5 15.5 20.7238 15.5 21V21.7C15.5 21.9761 15.7238 22.2 16 22.2C16.2761 22.2 16.5 21.9761 16.5 21.7V21C16.5 20.7238 16.2761 20.5 16 20.5Z" fill="black"/>
        </svg>
        <span v-html="message"></span>
    </div>
</template>
<script>
export default {
    name: "ToastPopup",
    props: {
        message: String,
        relative: Boolean
    }
}
</script>