import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import { store } from '@/main.js'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/kennisbank',
    name: 'Kennisbank',
    component: () => import('../views/KennisbankView.vue')
  },
  {
    path: '/berichten',
    name: 'Nieuws',
    component: () => import('../views/BerichtenView.vue')
  },
  {
    path: '/kennisbank/:catSlug',
    name: 'Categorie',
    props: true,
    component: () => import('../views/CategorieView.vue')
  },
  {
    path: '/artikel/:artikelSlug',
    alias: '/artikel/:artikelId',
    name: 'Artikel',
    props: true,
    component: () => import('../views/ArtikeldetailsView.vue')
  },
  {
    path: '/bericht/:berichtSlug',
    alias: '/bericht/:berichtId',
    name: 'Bericht',
    props: true,
    component: () => import('../views/BerichtdetailsView.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'Wachtwoord vergeten',
    props: true,
    component: () => import('../views/wwvergeten/EmailForm.vue')
  },
  {
    path: '/wachtwoord-vergeten/verificatie',
    name: 'Verificatiecode',
    props: true,
    component: () => import('../views/wwvergeten/VerificatieForm.vue')
  },
  {     
    path: '/wachtwoord-vergeten/nieuw-wachtwoord',
    name: 'Nieuwwachtwoord',
    props: true,
    component: () => import('../views/wwvergeten/PasswordForm.vue')
  },
  {
    path: '/wachtwoord-vergeten/succes',
    name: 'WwvergetenSucces',
    props: true,
    component: () => import('../views/wwvergeten/BedanktView.vue')
  },
  {
    path: '/tickets',
    name: 'Ticketoverzicht',
    props: true,
    component: () => import('../views/TicketsView.vue')
  },
  {
    path: '/mijn-instellingen',
    name: 'Mijn instellingen',
    props: true,
    component: () => import('../views/InstellingenView.vue')
  },
  {
    path: '/zoeken',
    name: 'Zoekresultaten',
    props: true,
    component: () => import('../views/ZoekresultatenView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    
    var content = document.getElementById('content');
    content.scrollTop = 0;
    
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/','/wachtwoord-vergeten','/wachtwoord-vergeten/verificatie','/wachtwoord-vergeten/nieuw-wachtwoord','/wachtwoord-vergeten/succes'];
    const authRequired = !publicPages.includes(to.path);
    const solunoapp = localStorage.getItem('solunoapp');
    const loggedin = JSON.parse(solunoapp).loggedin;

    console.log(store);

    // var storage = JSON.parse(solunoapp);
    // storage.artikelCat = null;
    // localStorage.setItem("solunoapp", JSON.stringify(storage));
    store.commit('setartikelCat',null);

    // set data-once to false to enable super search before going to new route
    document.body.setAttribute('data-once', 'false');
  
    if (authRequired && !loggedin) {
      return next({ 
        path: '/', 
        query: { returnUrl: to.path } 
      });
    }

    document.title = to.name + ' | ParisXP'
  
    next();
})

export default router
